//
// Color system
//
$dgl-black:				#000	!default;
$dgl-grey-strong:		#4D4D4D !default;
$dgl-grey-darker:		#757575 !default;
$dgl-grey-dark:			#C2C2C2 !default;
$dgl-grey:				#D8D8D8 !default;
$dgl-grey-mid:			#E6E6E6 !default;
$dgl-grey-light:		#F2F2F2 !default;
$dgl-grey-lighter:		#F8F8F8 !default;
$dgl-white:				#FFF	!default;

$dgl-blue: 				#4b6dfb !default;
$dgl-light-blue: 		#1090C8 !default;
$dgl-green: 			#008E28 !default;
$dgl-yellow:			#F0AD4E !default;
$dgl-red: 				#D72931 !default;
$dgl-purple:			#6443B5 !default;

$dgl-primary: 			$dgl-black !default;
$dgl-primary-dark:		#416AA6 !default;
$dgl-body-background-color: #F3F7F9 !default;

$dgl-green-status: 		#CDECAE !default;
$dgl-blue-status: 		#B6DDF2 !default;
$dgl-amber-status:		#F9E0A7 !default;
$dgl-red-status: 		#F8D7DA !default;

$dgl-success-status: $dgl-green-status;
$dgl-info-status: $dgl-blue-status;
$dgl-warning-status: $dgl-amber-status;
$dgl-danger-status: $dgl-red-status;

$dgl-gray-400: 			#A8A8A8 !default;
$dgl-gray-300: 			$dgl-grey-mid !default;
$dgl-gray-200: 			$dgl-grey-light !default;
$dgl-gray-100: 			$dgl-grey-lighter !default;


$dgl-discount:			#D72931;
$dgl-price:				$dgl-black;
$dgl-old-amount:		#ADADAD;
$dgl-notify:			#5D636A;

$headings-color:		#042d36 !default;

// Alerts
$dgl-success-text: 		#23550C !default;
$dgl-info-text: 		#0077CB !default;
$dgl-warning-text: 		#704F17 !default;
$dgl-danger-text: 		#471111 !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more
$normalize-font:	"Helvetica", arial, sans-serif;
$font-icon: "Icomoon";
$font-dsgl-logos: "Desigual-Logos" !default;
$font-family-light: "HelveticaNeueRoman";
$font-family-bold: "HelveticaNeueMedium";
$font-custom: "Custom";

// ICONS
$icon-font-size:					24px !default;


// Buttons
//
$dgl-btnlink-font-size-base:		1rem !default;
$dgl-btnlink-font-size-base-sm:		.9rem !default;
$dgl-btnlink-font-size-base-lg:     1.25rem !default;
$dgl-btn-black-hover-color:			$dgl-grey-strong !default;
$dgl-btn-font-size-base-sm:			1rem * .875 !default;



//
// Form Inputs
$dgl-input-font-size-base-sm:		1rem !default;
$dgl-input-font-size-base-lg:		1.5rem !default;
$dgl-input-border-color:			$dgl-black !default;
$dgl-input-focus-border-color:		$dgl-grey-darker !default;
$dgl-form-single-width:				36rem !default;
$dgl-form-single-width-sm:			41rem!default;
//
// Checkbox & radio button
$dgl-check-color: $dgl-primary;

$dgl-check-width: 1.5rem;
$dgl-check-active-width: 2px;

$dgl-check-custom-width: 1.875rem;
$dgl-check-custom-sm-width: 1rem;
$dgl-check-custom-lg-width: 4rem;
$dgl-check-border-width: 1px; //0.13em;

//Site
$dgl-max-width: 1920px;

// Navigation
$dgl-navbar-height-xs: 3.8rem;
$dgl-navbar-height-md: 4.85rem;
$dgl-navbar-fontsize: 1rem * .875 !default;


// TRANSITION SPEED
$transition-time:					.3s !default;
$transition-base-time:				$transition-time cubic-bezier(0.6, 0, 0, 1) !default;
$transition-enter-time:				$transition-time cubic-bezier(0.02, 0.65, 0, 1) !default;
$transition-leave-time:				$transition-time cubic-bezier(0.9, 0, 1, 0.87) !default;

$transition-base:					all $transition-base-time !default;
$transition-enter:					transform $transition-enter-time !default;
$transition-leave:					transform $transition-leave-time !default;

//Animation time
$fadeIN-time: 1s cubic-bezier(0.4, 0.0, 0.2, 1);

// LOGIN
$dgl-login-background: $dgl-grey-lighter						!default;
$dgl-login-image: "./assets/images/static/background-login.jpg" !default;


// FRAMEWORK OPTIONS
$dgl-enable-login-image:            false !default;


//Import icons variables
@import "icon-variables";

// Grid breakpoints

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1320px, xxl: 1600px) !default;


