/*
Starting point. Imports the rest of the elements.
To be imported in angular-cli styles.scss.
*/

// Variables
@import "site-variables";

// Framework CSS

// Framework 100% Bootstrap
//@import "global/index-boot";

// Framework with PrimeNG
@import "global/index-ui";


// Site Layouts
@import "layout/index";

// Site's and 3rd party components overrides
@import "components/index";
