.item-product {
   // position: relative;
    display: inline-block;
    width: 100%; 
    padding: 6%; 
    text-align: center;

    position: absolute;
    top: 0;
    left: 0;

    transition: transform .3s cubic-bezier(0.34, 1.13, 0.39, 0.99);
    
    &:hover {   
        transform: scale(1.1);
    }

    .product-colors & {
        position: relative;
               
        &:hover {   
            transform: scale(1);
        }
    }

}

    .item-product-check {
        position: absolute;
        top: 8%;
        right: 2%;
        border-radius: 50%;
        background-color: $dgl-primary;
        color: white;

        overflow: hidden;
        vertical-align: middle;
        width: 20%;
        height: auto;
    }

    .item-product-color {
        $item-product-color-size: 10%;
        position: relative;
        padding: 0;
        margin-top: 0; //8%;
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        li {
            cursor: default;
            width: $item-product-color-size;
            min-width: 4px;
            min-height: 4px;
            max-width: 8px;
            max-height: 8px;

            border-radius: 0;
            display: inline-block;
            margin-right: 6%;
           
            //border-radius: 50%;

            &:last-child {
                margin-right: 0;
            }

            display: var(--item-display-color);
            @include aspect-ratio(1, 1);

            &[style*="background-color: rgb(255, 255, 255)"] {
                box-shadow: inset 0 0 0 1px $dgl-bboa-color-grey-4;
            }
        }
    }

    .item-product-img {
        display: block;

        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: auto;
        margin-top: 3%;
        //background-size: contain;
        //background-repeat: no-repeat;

        @include aspect-ratio(2, 3);

        //background-image: url("https://media.desigual.com/i/desigual/18WWBW01_2000_G") !important;
    }
