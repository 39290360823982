
// Bg colors
.bg-green {
	background-color: $green;
} 
.bg-red {
	background-color: $red;
} 
.bg-gray-light {
	background-color: $dgl-gray-100;
} 
.bg-gray-mid {
	background-color: $dgl-gray-200;
} 
.bg-gray {
	background-color: $dgl-gray-300;
} 
.bg-gray-dark {
	background-color: $dgl-gray-400;
} 

// Filter colors

// WHITE
.color-group-0100 {
  background-color: rgb(240,240,240);
}

// BLACK
.color-group-0200 {
  background-color: rgba(53,48,47,1);
}

// RED
.color-group-0300 {
  background-color: rgba(216,30,5,1);
}

// GREEN
.color-group-0400 {
  background-color: rgba(39, 174, 96, 1);
}

// BLUE
.color-group-0500 {
  background-color: rgba(0,91,153,1);
}

// BROWN
.color-group-0600 {
  background-color: rgba(96,51,10,1);
}

// ORANGE
.color-group-0700 {
  background-color: rgba(242,132,17,1);
}

// YELLOW
.color-group-0800 {
  background-color: rgba(255,237,56,1);
}

// MATERIAL FINISHES
.color-group-0900 {
  background-color: rgb(255, 255, 255);
}