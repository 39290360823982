.p-progressbar {
  height: $progressBarHeight;
  background: $progressBarBg;
  border-radius: $borderRadius;

    .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: $progressBarValueBg;
    }

    .p-progressbar-label {
        color: $progressBarValueTextColor;
        line-height: $progressBarHeight;
    }
}
