$primaryLightColor: rgba(38,143,255,.5) !default;
$primaryColor: #007bff !default;
$primaryDarkColor: #0069d9 !default;
$primaryDarkerColor: #0062cc !default;
$primaryTextColor: #ffffff !default;
$primaryAccentBg: #cce5ff !default;

$highlightBg: $primaryColor !default;
$highlightTextColor: $primaryTextColor !default;

$progressBarHeight: $font-size-xs;

@import './_variables_light';
