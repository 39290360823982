.input-select{
	position: relative;
	z-index: 1;

	select{

		&.form-control {
			@include form-control-focus;
			appearance: none;
			@include custom-select-content;
			//color: inherit;
			background-color: transparent;
			cursor: pointer;
			
			&:focus{	
				background-color: transparent;
				border-color: $input-border-color;

				& + :after{
					@include custom-select-arrow-active;
				}
			}
			&.disabled,
		    &:disabled {
				background-color: $dgl-gray-300;
		        border-color: $dgl-gray-300;
		        color: $dgl-gray-400;
		        cursor: default;

		        &:after{
					@include custom-select-arrow;	
					top: .7em;
					font-size: $input-font-size-base;
					z-index: -1;
				}
			}
		}

		option {
			font-size: 1rem;
		}
	}
	
	&.disabled {
		&:after{
			z-index: 1;
			color: $dgl-gray-400;
		}
	}

	&:after{
		@include custom-select-arrow;	
		color: inherit;
		z-index: -1;
	}

	&:focus{
		outline: none;
		color: $dgl-btn-black-hover-color;

		select.form-control{
			color: inherit;
			border-color: currentColor;
		}

		&:after{
			@include custom-select-arrow-active;
		}
	}

	&:hover{
		color: $dgl-btn-black-hover-color;
		
		select.form-control{
			color: inherit;
			border-color: currentColor;
		}

		&:after{}
	}

	&.input-select-sm{

		select{

			&.form-control {
				padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
				padding-right: #{$input-btn-padding-x-sm + 2rem};
				line-height: $input-btn-line-height-sm;
				@include border-radius($input-border-radius-sm);
				height: auto;
				font-size: $dgl-input-font-size-base-sm;
			}
		}

		&:after{
			@include custom-select-arrow-sm;	
		}
	}

	&.input-select-lg{

		select{

			&.form-control {
				padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
				padding-right: #{$input-btn-padding-x-lg + 2rem};
				line-height: $input-btn-line-height-lg;
				@include border-radius($input-border-radius-lg);
				height: auto;
				font-size: $dgl-input-font-size-base-lg;
			}
		}

		&:after{
			@include custom-select-arrow-lg;	
		}
	}
}