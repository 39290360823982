.p-sidebar-right {
  width: 175px!important;
  background-color: $dgl-bboa-color-grey-0;
  border: 0;
  border-left: 1px solid $dgl-bboa-color-grey-4;
  padding: 0;

  .button-trigger-close {
    padding: 0 1.5rem;
  }

  .p-sidebar-content {
    padding: 0;

    .ui-sidebar-wrapper {
      padding: 0 1rem;
    }
  }

  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }
}
