table {
  .table-group {
    width: 100%;

    tr {
      td {
        width: 24%;
      }
    }

    .ng-star-inserted {
      padding: 1em 1em;
    }
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  width: 24%;
}
