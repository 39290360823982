@mixin custom-select-content{
	text-align: left;
	box-shadow: none !important;
	border-color: $input-border-color;
	padding-right: #{$input-btn-padding-x + 1.8rem};	
}

@mixin custom-select-content-sm{
	padding: $input-btn-padding-y-sm #{$input-btn-padding-x-sm + 2rem} $input-btn-padding-y-sm .8rem;
}

@mixin custom-select-content-lg{
	padding-right: #{$input-btn-padding-x-lg + 2rem};
}

@mixin custom-select-arrow{
	display: flex;
	align-items: center;
	font-family: $font-icon;
	content: $icon-chevron-down;
	font-size: $font-size-lg;
	width: 1em;
	height: 100%;

	line-height: 1;
	border: none;

	position: absolute;
	top: 0;
	bottom: 0;
	right: #{$input-btn-padding-x - .5rem};
	
    transform-origin: 50% 50% 0;
	transition: transform .15s;
}

@mixin custom-select-arrow-sm{
	right: #{$input-btn-padding-x-sm - .5rem};
}

@mixin custom-select-arrow-lg{
	right: #{$input-btn-padding-x-lg - .5rem};
}

@mixin custom-select-arrow-active{
	transform: rotate(180deg);
}