/*
Site-wide variables. Includes app and 3rd party variables.
*/


// Framework CSS variables
//@import "global/variables";

//@import "global/bootstrap/index";
// Framework mixins
//@import "global/mixins/index";

//@import "global/ui-framework/ui-variables";



//	Import variables from framework
@import "global/variables";
@import "global/variables-custom";



// Bootstrap
@import "../../../node_modules/bootstrap/scss/functions";
@import "global/bootstrap/bootstrap-variables"; //	Update/add variables
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

//	Framework mixins

@import "global/mixins/index";

@import "global/ui-framework/ui-variables";

@import "layout/utils";