@mixin full-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin pdp-data-title {
	color: $dgl-bboa-color-grey-6;
  font-size: 14px;
  line-height: 110%;
}

@mixin grid-category-title {
  font-family: $font-custom;
  font-size: $font-size-base * 2;
  color: $dgl-bboa-color-grey-6;
  line-height: 80%;
  letter-spacing: .5px;
  margin-bottom: .3rem;
  text-transform: uppercase;
}

$browser-base-font: 16; // Default

@function em($pixels, $context: $browser-base-font) {
  @return #{$pixels/$context}em;
}

@mixin separator-line {
  content: "";
  height: calc(100% - 100px);
  width: 1px;
  background-color: $dgl-bboa-color-grey-4;
  top: 80px;
  position: absolute;
  left: 50%;
  z-index: 1;
}  


.button-trigger-close {
  color: $dgl-bboa-color-grey-6;
  height: $dgl-bboa-header-height;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  [class^="icon-"] {
      font-size: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;
  }

  .button-trigger-close-text {
    font-size: 24/16 * 1rem;
    font-family: $font-family-bold;
    font-weight: bold;
  }

  &:hover {
      background: $dgl-bboa-menu-secondary-color !important;
      border: 0 !important;
      color: currentColor !important;
  }
}

.grid-length-0 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@mixin grid-reference {
  font-family: $font-family-light;
  font-size: 0.6rem; //$font-size-xs;
  text-transform: uppercase;
  color: $dgl-bboa-color-grey-5;
  line-height: 1;

  /*.grid-group-header & {
      max-width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }*/
}  

.grid-reference {
  @include grid-reference;
}


.chartjs-render-monitor {
  animation: chartjs-render-animation 0s 0s forwards !important;
}

@keyframes animation-opacity {
  0%   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin animation-opacity {
  opacity: 0;
  animation-name: animation-opacity;
  animation-duration: .2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: .3s;
}  
