@font-face{
    font-family:"Icomoon";
    src:	url("icomoon.woff?luon03") format("woff"),
			url("icomoon.ttf?luon03") format("truetype");
}

@font-face{
    font-family:"Desigual-Logos";
    src:	url("desigual-logos.woff?230517") format("woff"),
			url("desigual-logos.ttf?230517") format("truetype");
}


