body{
  //AA Font
  -webkit-font-smoothing: antialiased;
}

//
// Headings
//

@mixin heading {
  margin-bottom: $headings-margin-bottom;
  //DGL Font-family
  //font-family: $font-family-bold;
  @include regular-font;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, h2, h4, h5, h6,
.h1, .h2, .h4, .h5, .h6 {
  @include heading;
}

//DGL H1
h1, 
.h1{
  @include heading;
  font-size: 3em;
}

//DGL H2
h2, 
.h2{
  @include heading;
  font-size: 2.25em;
}

//DGL H3
h3, 
.h3{
  @include heading;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

//DGL Custom font medium as bold font
b,
strong{
  font-weight: normal;
  font-family: $font-family-bold;
}

.font-light{
  font-family: $font-family-light;
}

.font-bold{
  font-family: $font-family-bold;
}

.font-custom{
  @include font-custom;
}


//required 
.dgl-required {
  color: $dgl-red;
  font-size: 1em;
  //margin-left: .2em;
  //vertical-align: super;
}