/*
Utils
*/

.text-center {
    text-align: center;
}

.text-none{
	text-transform: none !important;
}

.click {
    cursor: pointer;
}