.icon,
[class^="icon-"],
[class*=" icon-"] {
  @include icon-style;
  font-size: $icon-font-size;
}

// This class adds a circle around the icon
.icon-circle-dgl {
  display: inline-block;
  border: .05rem solid currentColor;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  flex-shrink: 0;
  align-self: center;
  line-height: 2em !important;

  &:before {
    font-size: 1.2em;
  }

  &.icon-close-dgl {
    &:before {
      font-size: 1em;
    }
  }
}

.icon-circle-dgl-xs {
  font-size: .75rem;
}

// Iconography classes
.icon-chevron-double-left {
  &:before {
    content: $icon-chevron-double-left; 
  }
}
.icon-chevron-double-right {
  &:before {
    content: $icon-chevron-double-right; 
  }
}
.icon-logo-mydesigual {
  &:before {
    content: $icon-logo-mydesigual; 
  }
}
.icon-product {
  &:before {
    content: $icon-product; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-magnifying-glass-plus {
  &:before {
    content: $icon-magnifying-glass-plus; 
  }
}
.icon-magnifying-glass-minus {
  &:before {
    content: $icon-magnifying-glass-minus; 
  }
}
.icon-sound-muted-bigger {
  &:before {
    content: $icon-sound-muted-bigger; 
  }
}
.icon-sound-muted-medium {
  &:before {
    content: $icon-sound-muted-medium; 
  }
}
.icon-sound-bigger {
  &:before {
    content: $icon-sound-bigger; 
  }
}
.icon-sound-medium {
  &:before {
    content: $icon-sound-medium; 
  }
}
.icon-sent {
  &:before {
    content: $icon-sent; 
  }
}
.icon-returned {
  &:before {
    content: $icon-returned; 
  }
}
.icon-help-with-circle-black {
  &:before {
    content: $icon-help-with-circle-black; 
  }
}
.icon-preparing {
  &:before {
    content: $icon-preparing; 
  }
}
.icon-play-bigger {
  &:before {
    content: $icon-play-bigger; 
  }
}
.icon-play-medium {
  &:before {
    content: $icon-play-medium; 
  }
}
.icon-location-pin-black {
  &:before {
    content: $icon-location-pin-black; 
  }
}
.icon-emoji-surprised {
  &:before {
    content: $icon-emoji-surprised; 
  }
}
.icon-pick {
  &:before {
    content: $icon-pick; 
  }
}
.icon-pause-bigger {
  &:before {
    content: $icon-pause-bigger; 
  }
}
.icon-pause-medium {
  &:before {
    content: $icon-pause-medium; 
  }
}
.icon-partially-returned {
  &:before {
    content: $icon-partially-returned; 
  }
}
.icon-online {
  &:before {
    content: $icon-online; 
  }
}
.icon-magic {
  &:before {
    content: $icon-magic; 
  }
}
.icon-magic-three {
  &:before {
    content: $icon-magic-three; 
  }
}
.icon-magic-two {
  &:before {
    content: $icon-magic-two; 
  }
}
.icon-magic-one {
  &:before {
    content: $icon-magic-one; 
  }
}
.icon-magic-logo {
  &:before {
    content: $icon-magic-logo; 
  }
}
.icon-linear-line {
  &:before {
    content: $icon-linear-line; 
  }
}
.icon-heart-break-right {
  &:before {
    content: $icon-heart-break-right; 
  }
}
.icon-heart-break-left {
  &:before {
    content: $icon-heart-break-left; 
  }
}
.icon-filter-sort {
  &:before {
    content: $icon-filter-sort; 
  }
}
.icon-check-small {
  &:before {
    content: $icon-check-small; 
  }
}
.icon-check-bubble {
  &:before {
    content: $icon-check-bubble; 
  }
}
.icon-check-bubble-black {
  &:before {
    content: $icon-check-bubble-black; 
  }
}
.icon-canceled {
  &:before {
    content: $icon-canceled; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-360 {
  &:before {
    content: $icon-360; 
  }
}
.icon-user-mask-dgl {
  &:before {
    content: $icon-user-mask-dgl; 
  }
}
.icon-user-dgl {
  &:before {
    content: $icon-user-dgl; 
  }
}
.icon-cloud-upload-dgl {
  &:before {
    content: $icon-cloud-upload-dgl; 
  }
}
.icon-mask-batman-dgl {
  &:before {
    content: $icon-mask-batman-dgl; 
  }
}
.icon-home-dgl {
  &:before {
    content: $icon-home-dgl; 
  }
}
.icon-mail-dgl {
  &:before {
    content: $icon-mail-dgl; 
  }
}
.icon-scan-dgl {
  &:before {
    content: $icon-scan-dgl; 
  }
}
.icon-logodesigual {
  &:before {
    content: $icon-logodesigual; 
  }
}
.icon-bin-dgl {
  &:before {
    content: $icon-bin-dgl; 
  }
}
.icon-cart-dgl {
  &:before {
    content: $icon-cart-dgl; 
  }
}
.icon-close-dgl {
  &:before {
    content: $icon-close-dgl; 
  }
}
.icon-up-dgl {
  &:before {
    content: $icon-up-dgl; 
  }
}
.icon-down-dgl {
  &:before {
    content: $icon-down-dgl; 
  }
}
.icon-back-dgl {
  &:before {
    content: $icon-back-dgl; 
  }
}
.icon-forward-dgl {
  &:before {
    content: $icon-forward-dgl; 
  }
}
.icon-sign-minus-dgl {
  &:before {
    content: $icon-sign-minus-dgl; 
  }
}
.icon-sign-plus-dgl {
  &:before {
    content: $icon-sign-plus-dgl; 
  }
}
.icon-search-dgl {
  &:before {
    content: $icon-search-dgl; 
  }
}
.icon-sign-warning-dgl {
  &:before {
    content: $icon-sign-warning-dgl; 
  }
}
.icon-info-dgl {
  &:before {
    content: $icon-info-dgl; 
  }
}
.icon-check-dgl {
  &:before {
    content: $icon-check-dgl; 
  }
}
.icon-add-to-list {
  &:before {
    content: $icon-add-to-list; 
  }
}
.icon-add-user {
  &:before {
    content: $icon-add-user; 
  }
}
.icon-address {
  &:before {
    content: $icon-address; 
  }
}
.icon-adjust {
  &:before {
    content: $icon-adjust; 
  }
}
.icon-air {
  &:before {
    content: $icon-air; 
  }
}
.icon-aircraft-landing {
  &:before {
    content: $icon-aircraft-landing; 
  }
}
.icon-aircraft-take-off {
  &:before {
    content: $icon-aircraft-take-off; 
  }
}
.icon-aircraft {
  &:before {
    content: $icon-aircraft; 
  }
}
.icon-align-bottom {
  &:before {
    content: $icon-align-bottom; 
  }
}
.icon-align-horizontal-middle {
  &:before {
    content: $icon-align-horizontal-middle; 
  }
}
.icon-align-left {
  &:before {
    content: $icon-align-left; 
  }
}
.icon-align-right {
  &:before {
    content: $icon-align-right; 
  }
}
.icon-align-top {
  &:before {
    content: $icon-align-top; 
  }
}
.icon-align-vertical-middle {
  &:before {
    content: $icon-align-vertical-middle; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-area-graph {
  &:before {
    content: $icon-area-graph; 
  }
}
.icon-arrow-bold-down {
  &:before {
    content: $icon-arrow-bold-down; 
  }
}
.icon-arrow-bold-left {
  &:before {
    content: $icon-arrow-bold-left; 
  }
}
.icon-arrow-bold-right {
  &:before {
    content: $icon-arrow-bold-right; 
  }
}
.icon-arrow-bold-up {
  &:before {
    content: $icon-arrow-bold-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-long-down {
  &:before {
    content: $icon-arrow-long-down; 
  }
}
.icon-arrow-long-left {
  &:before {
    content: $icon-arrow-long-left; 
  }
}
.icon-arrow-long-right {
  &:before {
    content: $icon-arrow-long-right; 
  }
}
.icon-arrow-long-up {
  &:before {
    content: $icon-arrow-long-up; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-with-circle-down {
  &:before {
    content: $icon-arrow-with-circle-down; 
  }
}
.icon-arrow-with-circle-left {
  &:before {
    content: $icon-arrow-with-circle-left; 
  }
}
.icon-arrow-with-circle-right {
  &:before {
    content: $icon-arrow-with-circle-right; 
  }
}
.icon-arrow-with-circle-up {
  &:before {
    content: $icon-arrow-with-circle-up; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-awareness-ribbon {
  &:before {
    content: $icon-awareness-ribbon; 
  }
}
.icon-back-in-time {
  &:before {
    content: $icon-back-in-time; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-bar-graph {
  &:before {
    content: $icon-bar-graph; 
  }
}
.icon-battery {
  &:before {
    content: $icon-battery; 
  }
}
.icon-beamed-note {
  &:before {
    content: $icon-beamed-note; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-blackboard {
  &:before {
    content: $icon-blackboard; 
  }
}
.icon-block {
  &:before {
    content: $icon-block; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bookmarks {
  &:before {
    content: $icon-bookmarks; 
  }
}
.icon-bowl {
  &:before {
    content: $icon-bowl; 
  }
}
.icon-box {
  &:before {
    content: $icon-box; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-browser {
  &:before {
    content: $icon-browser; 
  }
}
.icon-brush {
  &:before {
    content: $icon-brush; 
  }
}
.icon-bucket {
  &:before {
    content: $icon-bucket; 
  }
}
.icon-bug {
  &:before {
    content: $icon-bug; 
  }
}
.icon-cake {
  &:before {
    content: $icon-cake; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-ccw {
  &:before {
    content: $icon-ccw; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-small-down {
  &:before {
    content: $icon-chevron-small-down; 
  }
}
.icon-chevron-small-left {
  &:before {
    content: $icon-chevron-small-left; 
  }
}
.icon-chevron-small-right {
  &:before {
    content: $icon-chevron-small-right; 
  }
}
.icon-chevron-small-up {
  &:before {
    content: $icon-chevron-small-up; 
  }
}
.icon-chevron-thin-down {
  &:before {
    content: $icon-chevron-thin-down; 
  }
}
.icon-chevron-thin-left {
  &:before {
    content: $icon-chevron-thin-left; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right; 
  }
}
.icon-chevron-thin-up {
  &:before {
    content: $icon-chevron-thin-up; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-chevron-with-circle-down {
  &:before {
    content: $icon-chevron-with-circle-down; 
  }
}
.icon-chevron-with-circle-left {
  &:before {
    content: $icon-chevron-with-circle-left; 
  }
}
.icon-chevron-with-circle-right {
  &:before {
    content: $icon-chevron-with-circle-right; 
  }
}
.icon-chevron-with-circle-up {
  &:before {
    content: $icon-chevron-with-circle-up; 
  }
}
.icon-circle-with-cross {
  &:before {
    content: $icon-circle-with-cross; 
  }
}
.icon-circle-with-minus {
  &:before {
    content: $icon-circle-with-minus; 
  }
}
.icon-circle-with-plus {
  &:before {
    content: $icon-circle-with-plus; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-circular-graph {
  &:before {
    content: $icon-circular-graph; 
  }
}
.icon-clapperboard {
  &:before {
    content: $icon-clapperboard; 
  }
}
.icon-classic-computer {
  &:before {
    content: $icon-classic-computer; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-colours {
  &:before {
    content: $icon-colours; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-controller-fast-backward {
  &:before {
    content: $icon-controller-fast-backward; 
  }
}
.icon-controller-fast-forward {
  &:before {
    content: $icon-controller-fast-forward; 
  }
}
.icon-controller-jump-to-start {
  &:before {
    content: $icon-controller-jump-to-start; 
  }
}
.icon-controller-next {
  &:before {
    content: $icon-controller-next; 
  }
}
.icon-controller-paus {
  &:before {
    content: $icon-controller-paus; 
  }
}
.icon-controller-play {
  &:before {
    content: $icon-controller-play; 
  }
}
.icon-controller-record {
  &:before {
    content: $icon-controller-record; 
  }
}
.icon-controller-stop {
  &:before {
    content: $icon-controller-stop; 
  }
}
.icon-controller-volume {
  &:before {
    content: $icon-controller-volume; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-creative-commons-attribution {
  &:before {
    content: $icon-creative-commons-attribution; 
  }
}
.icon-creative-commons-noderivs {
  &:before {
    content: $icon-creative-commons-noderivs; 
  }
}
.icon-creative-commons-noncommercial-eu {
  &:before {
    content: $icon-creative-commons-noncommercial-eu; 
  }
}
.icon-creative-commons-noncommercial-us {
  &:before {
    content: $icon-creative-commons-noncommercial-us; 
  }
}
.icon-creative-commons-public-domain {
  &:before {
    content: $icon-creative-commons-public-domain; 
  }
}
.icon-creative-commons-remix {
  &:before {
    content: $icon-creative-commons-remix; 
  }
}
.icon-creative-commons-share {
  &:before {
    content: $icon-creative-commons-share; 
  }
}
.icon-creative-commons-sharealike {
  &:before {
    content: $icon-creative-commons-sharealike; 
  }
}
.icon-creative-commons {
  &:before {
    content: $icon-creative-commons; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-credit {
  &:before {
    content: $icon-credit; 
  }
}
.icon-crop {
  &:before {
    content: $icon-crop; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-cup {
  &:before {
    content: $icon-cup; 
  }
}
.icon-cw {
  &:before {
    content: $icon-cw; 
  }
}
.icon-cycle {
  &:before {
    content: $icon-cycle; 
  }
}
.icon-database {
  &:before {
    content: $icon-database; 
  }
}
.icon-dial-pad {
  &:before {
    content: $icon-dial-pad; 
  }
}
.icon-direction {
  &:before {
    content: $icon-direction; 
  }
}
.icon-document-landscape {
  &:before {
    content: $icon-document-landscape; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-documents {
  &:before {
    content: $icon-documents; 
  }
}
.icon-dot-single {
  &:before {
    content: $icon-dot-single; 
  }
}
.icon-dots-three-horizontal {
  &:before {
    content: $icon-dots-three-horizontal; 
  }
}
.icon-dots-three-vertical {
  &:before {
    content: $icon-dots-three-vertical; 
  }
}
.icon-dots-two-horizontal {
  &:before {
    content: $icon-dots-two-horizontal; 
  }
}
.icon-dots-two-vertical {
  &:before {
    content: $icon-dots-two-vertical; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-drink {
  &:before {
    content: $icon-drink; 
  }
}
.icon-drive {
  &:before {
    content: $icon-drive; 
  }
}
.icon-drop {
  &:before {
    content: $icon-drop; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-emoji-flirt {
  &:before {
    content: $icon-emoji-flirt; 
  }
}
.icon-emoji-happy {
  &:before {
    content: $icon-emoji-happy; 
  }
}
.icon-emoji-neutral {
  &:before {
    content: $icon-emoji-neutral; 
  }
}
.icon-emoji-sad {
  &:before {
    content: $icon-emoji-sad; 
  }
}
.icon-erase {
  &:before {
    content: $icon-erase; 
  }
}
.icon-eraser {
  &:before {
    content: $icon-eraser; 
  }
}
.icon-export {
  &:before {
    content: $icon-export; 
  }
}
.icon-eye-with-line {
  &:before {
    content: $icon-eye-with-line; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-feather {
  &:before {
    content: $icon-feather; 
  }
}
.icon-fingerprint {
  &:before {
    content: $icon-fingerprint; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-flash {
  &:before {
    content: $icon-flash; 
  }
}
.icon-flashlight {
  &:before {
    content: $icon-flashlight; 
  }
}
.icon-flat-brush {
  &:before {
    content: $icon-flat-brush; 
  }
}
.icon-flow-branch {
  &:before {
    content: $icon-flow-branch; 
  }
}
.icon-flow-cascade {
  &:before {
    content: $icon-flow-cascade; 
  }
}
.icon-flow-line {
  &:before {
    content: $icon-flow-line; 
  }
}
.icon-flow-parallel {
  &:before {
    content: $icon-flow-parallel; 
  }
}
.icon-flow-tree {
  &:before {
    content: $icon-flow-tree; 
  }
}
.icon-flower {
  &:before {
    content: $icon-flower; 
  }
}
.icon-folder-images {
  &:before {
    content: $icon-folder-images; 
  }
}
.icon-folder-music {
  &:before {
    content: $icon-folder-music; 
  }
}
.icon-folder-video {
  &:before {
    content: $icon-folder-video; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-funnel {
  &:before {
    content: $icon-funnel; 
  }
}
.icon-game-controller {
  &:before {
    content: $icon-game-controller; 
  }
}
.icon-gauge {
  &:before {
    content: $icon-gauge; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-graduation-cap {
  &:before {
    content: $icon-graduation-cap; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-hair-cross {
  &:before {
    content: $icon-hair-cross; 
  }
}
.icon-hand {
  &:before {
    content: $icon-hand; 
  }
}
.icon-heart-outlined {
  &:before {
    content: $icon-heart-outlined; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-help-with-circle {
  &:before {
    content: $icon-help-with-circle; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass; 
  }
}
.icon-image-inverted {
  &:before {
    content: $icon-image-inverted; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-infinity {
  &:before {
    content: $icon-infinity; 
  }
}
.icon-info-with-circle {
  &:before {
    content: $icon-info-with-circle; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-install {
  &:before {
    content: $icon-install; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-keyboard {
  &:before {
    content: $icon-keyboard; 
  }
}
.icon-lab-flask {
  &:before {
    content: $icon-lab-flask; 
  }
}
.icon-landline {
  &:before {
    content: $icon-landline; 
  }
}
.icon-language {
  &:before {
    content: $icon-language; 
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf; 
  }
}
.icon-level-down {
  &:before {
    content: $icon-level-down; 
  }
}
.icon-level-up {
  &:before {
    content: $icon-level-up; 
  }
}
.icon-lifebuoy {
  &:before {
    content: $icon-lifebuoy; 
  }
}
.icon-light-bulb {
  &:before {
    content: $icon-light-bulb; 
  }
}
.icon-light-down {
  &:before {
    content: $icon-light-down; 
  }
}
.icon-light-up {
  &:before {
    content: $icon-light-up; 
  }
}
.icon-line-graph {
  &:before {
    content: $icon-line-graph; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-location-pin {
  &:before {
    content: $icon-location-pin; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock-open {
  &:before {
    content: $icon-lock-open; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-loop {
  &:before {
    content: $icon-loop; 
  }
}
.icon-magnet {
  &:before {
    content: $icon-magnet; 
  }
}
.icon-magnifying-glass {
  &:before {
    content: $icon-magnifying-glass; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-man {
  &:before {
    content: $icon-man; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-mask {
  &:before {
    content: $icon-mask; 
  }
}
.icon-medal {
  &:before {
    content: $icon-medal; 
  }
}
.icon-megaphone {
  &:before {
    content: $icon-megaphone; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-merge {
  &:before {
    content: $icon-merge; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-mic {
  &:before {
    content: $icon-mic; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-modern-mic {
  &:before {
    content: $icon-modern-mic; 
  }
}
.icon-moon {
  &:before {
    content: $icon-moon; 
  }
}
.icon-mouse-pointer {
  &:before {
    content: $icon-mouse-pointer; 
  }
}
.icon-mouse {
  &:before {
    content: $icon-mouse; 
  }
}
.icon-music {
  &:before {
    content: $icon-music; 
  }
}
.icon-network {
  &:before {
    content: $icon-network; 
  }
}
.icon-new-message {
  &:before {
    content: $icon-new-message; 
  }
}
.icon-new {
  &:before {
    content: $icon-new; 
  }
}
.icon-news {
  &:before {
    content: $icon-news; 
  }
}
.icon-newsletter {
  &:before {
    content: $icon-newsletter; 
  }
}
.icon-note {
  &:before {
    content: $icon-note; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-notifications-off {
  &:before {
    content: $icon-notifications-off; 
  }
}
.icon-old-mobile {
  &:before {
    content: $icon-old-mobile; 
  }
}
.icon-old-phone {
  &:before {
    content: $icon-old-phone; 
  }
}
.icon-open-book {
  &:before {
    content: $icon-open-book; 
  }
}
.icon-palette {
  &:before {
    content: $icon-palette; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-popup {
  &:before {
    content: $icon-popup; 
  }
}
.icon-power-plug {
  &:before {
    content: $icon-power-plug; 
  }
}
.icon-price-ribbon {
  &:before {
    content: $icon-price-ribbon; 
  }
}
.icon-price-tag {
  &:before {
    content: $icon-price-tag; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-progress-empty {
  &:before {
    content: $icon-progress-empty; 
  }
}
.icon-progress-full {
  &:before {
    content: $icon-progress-full; 
  }
}
.icon-progress-one {
  &:before {
    content: $icon-progress-one; 
  }
}
.icon-progress-two {
  &:before {
    content: $icon-progress-two; 
  }
}
.icon-publish {
  &:before {
    content: $icon-publish; 
  }
}
.icon-quote {
  &:before {
    content: $icon-quote; 
  }
}
.icon-radio {
  &:before {
    content: $icon-radio; 
  }
}
.icon-remove-user {
  &:before {
    content: $icon-remove-user; 
  }
}
.icon-reply-all {
  &:before {
    content: $icon-reply-all; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-resize-100 {
  &:before {
    content: $icon-resize-100; 
  }
}
.icon-resize-full-screen {
  &:before {
    content: $icon-resize-full-screen; 
  }
}
.icon-retweet {
  &:before {
    content: $icon-retweet; 
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket; 
  }
}
.icon-round-brush {
  &:before {
    content: $icon-round-brush; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-ruler {
  &:before {
    content: $icon-ruler; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-scissors {
  &:before {
    content: $icon-scissors; 
  }
}
.icon-select-arrows {
  &:before {
    content: $icon-select-arrows; 
  }
}
.icon-share-alternative {
  &:before {
    content: $icon-share-alternative; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-shareable {
  &:before {
    content: $icon-shareable; 
  }
}
.icon-shield {
  &:before {
    content: $icon-shield; 
  }
}
.icon-shop {
  &:before {
    content: $icon-shop; 
  }
}
.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag; 
  }
}
.icon-shopping-basket {
  &:before {
    content: $icon-shopping-basket; 
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart; 
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle; 
  }
}
.icon-signal {
  &:before {
    content: $icon-signal; 
  }
}
.icon-sound-mix {
  &:before {
    content: $icon-sound-mix; 
  }
}
.icon-sound-mute {
  &:before {
    content: $icon-sound-mute; 
  }
}
.icon-sound {
  &:before {
    content: $icon-sound; 
  }
}
.icon-sports-club {
  &:before {
    content: $icon-sports-club; 
  }
}
.icon-spreadsheet {
  &:before {
    content: $icon-spreadsheet; 
  }
}
.icon-squared-cross {
  &:before {
    content: $icon-squared-cross; 
  }
}
.icon-squared-minus {
  &:before {
    content: $icon-squared-minus; 
  }
}
.icon-squared-plus {
  &:before {
    content: $icon-squared-plus; 
  }
}
.icon-star-outlined {
  &:before {
    content: $icon-star-outlined; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch; 
  }
}
.icon-suitcase {
  &:before {
    content: $icon-suitcase; 
  }
}
.icon-swap {
  &:before {
    content: $icon-swap; 
  }
}
.icon-sweden {
  &:before {
    content: $icon-sweden; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-tablet-mobile-combo {
  &:before {
    content: $icon-tablet-mobile-combo; 
  }
}
.icon-tablet {
  &:before {
    content: $icon-tablet; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-text-document-inverted {
  &:before {
    content: $icon-text-document-inverted; 
  }
}
.icon-text-document {
  &:before {
    content: $icon-text-document; 
  }
}
.icon-text {
  &:before {
    content: $icon-text; 
  }
}
.icon-thermometer {
  &:before {
    content: $icon-thermometer; 
  }
}
.icon-thumbs-down {
  &:before {
    content: $icon-thumbs-down; 
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up; 
  }
}
.icon-thunder-cloud {
  &:before {
    content: $icon-thunder-cloud; 
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket; 
  }
}
.icon-time-slot {
  &:before {
    content: $icon-time-slot; 
  }
}
.icon-tools {
  &:before {
    content: $icon-tools; 
  }
}
.icon-traffic-cone {
  &:before {
    content: $icon-traffic-cone; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-tree {
  &:before {
    content: $icon-tree; 
  }
}
.icon-triangle-down {
  &:before {
    content: $icon-triangle-down; 
  }
}
.icon-triangle-left {
  &:before {
    content: $icon-triangle-left; 
  }
}
.icon-triangle-right {
  &:before {
    content: $icon-triangle-right; 
  }
}
.icon-triangle-up {
  &:before {
    content: $icon-triangle-up; 
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy; 
  }
}
.icon-tv {
  &:before {
    content: $icon-tv; 
  }
}
.icon-typing {
  &:before {
    content: $icon-typing; 
  }
}
.icon-uninstall {
  &:before {
    content: $icon-uninstall; 
  }
}
.icon-unread {
  &:before {
    content: $icon-unread; 
  }
}
.icon-untag {
  &:before {
    content: $icon-untag; 
  }
}
.icon-upload-to-cloud {
  &:before {
    content: $icon-upload-to-cloud; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-v-card {
  &:before {
    content: $icon-v-card; 
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-vinyl {
  &:before {
    content: $icon-vinyl; 
  }
}
.icon-voicemail {
  &:before {
    content: $icon-voicemail; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-water {
  &:before {
    content: $icon-water; 
  }
}
.icon-px-with-circle {
  &:before {
    content: $icon-px-with-circle; 
  }
}
.icon-px {
  &:before {
    content: $icon-px; 
  }
}
.icon-app-store {
  &:before {
    content: $icon-app-store; 
  }
}
.icon-baidu {
  &:before {
    content: $icon-baidu; 
  }
}
.icon-basecamp {
  &:before {
    content: $icon-basecamp; 
  }
}
.icon-behance {
  &:before {
    content: $icon-behance; 
  }
}
.icon-creative-cloud {
  &:before {
    content: $icon-creative-cloud; 
  }
}
.icon-dribbble-with-circle {
  &:before {
    content: $icon-dribbble-with-circle; 
  }
}
.icon-dribbble {
  &:before {
    content: $icon-dribbble; 
  }
}
.icon-dropbox {
  &:before {
    content: $icon-dropbox; 
  }
}
.icon-evernote {
  &:before {
    content: $icon-evernote; 
  }
}
.icon-facebook-with-circle {
  &:before {
    content: $icon-facebook-with-circle; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-flattr {
  &:before {
    content: $icon-flattr; 
  }
}
.icon-flickr-with-circle {
  &:before {
    content: $icon-flickr-with-circle; 
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr; 
  }
}
.icon-foursquare {
  &:before {
    content: $icon-foursquare; 
  }
}
.icon-github-with-circle {
  &:before {
    content: $icon-github-with-circle; 
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-google-drive {
  &:before {
    content: $icon-google-drive; 
  }
}
.icon-google-hangouts {
  &:before {
    content: $icon-google-hangouts; 
  }
}
.icon-google-play {
  &:before {
    content: $icon-google-play; 
  }
}
.icon-google-with-circle {
  &:before {
    content: $icon-google-with-circle; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-grooveshark {
  &:before {
    content: $icon-grooveshark; 
  }
}
.icon-houzz {
  &:before {
    content: $icon-houzz; 
  }
}
.icon-icloud {
  &:before {
    content: $icon-icloud; 
  }
}
.icon-instagram-with-circle {
  &:before {
    content: $icon-instagram-with-circle; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-lastfm-with-circle {
  &:before {
    content: $icon-lastfm-with-circle; 
  }
}
.icon-lastfm {
  &:before {
    content: $icon-lastfm; 
  }
}
.icon-linkedin-with-circle {
  &:before {
    content: $icon-linkedin-with-circle; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-mail-with-circle {
  &:before {
    content: $icon-mail-with-circle; 
  }
}
.icon-medium-with-circle {
  &:before {
    content: $icon-medium-with-circle; 
  }
}
.icon-medium {
  &:before {
    content: $icon-medium; 
  }
}
.icon-mixi {
  &:before {
    content: $icon-mixi; 
  }
}
.icon-onedrive {
  &:before {
    content: $icon-onedrive; 
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal; 
  }
}
.icon-picasa {
  &:before {
    content: $icon-picasa; 
  }
}
.icon-pinterest-with-circle {
  &:before {
    content: $icon-pinterest-with-circle; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-qq-with-circle {
  &:before {
    content: $icon-qq-with-circle; 
  }
}
.icon-qq {
  &:before {
    content: $icon-qq; 
  }
}
.icon-raft-with-circle {
  &:before {
    content: $icon-raft-with-circle; 
  }
}
.icon-raft {
  &:before {
    content: $icon-raft; 
  }
}
.icon-rainbow {
  &:before {
    content: $icon-rainbow; 
  }
}
.icon-rdio-with-circle {
  &:before {
    content: $icon-rdio-with-circle; 
  }
}
.icon-rdio {
  &:before {
    content: $icon-rdio; 
  }
}
.icon-renren {
  &:before {
    content: $icon-renren; 
  }
}
.icon-scribd {
  &:before {
    content: $icon-scribd; 
  }
}
.icon-sina-weibo {
  &:before {
    content: $icon-sina-weibo; 
  }
}
.icon-skype-with-circle {
  &:before {
    content: $icon-skype-with-circle; 
  }
}
.icon-skype {
  &:before {
    content: $icon-skype; 
  }
}
.icon-slideshare {
  &:before {
    content: $icon-slideshare; 
  }
}
.icon-smashing {
  &:before {
    content: $icon-smashing; 
  }
}
.icon-soundcloud {
  &:before {
    content: $icon-soundcloud; 
  }
}
.icon-spotify-with-circle {
  &:before {
    content: $icon-spotify-with-circle; 
  }
}
.icon-spotify {
  &:before {
    content: $icon-spotify; 
  }
}
.icon-stumbleupon-with-circle {
  &:before {
    content: $icon-stumbleupon-with-circle; 
  }
}
.icon-stumbleupon {
  &:before {
    content: $icon-stumbleupon; 
  }
}
.icon-swarm {
  &:before {
    content: $icon-swarm; 
  }
}
.icon-tripadvisor {
  &:before {
    content: $icon-tripadvisor; 
  }
}
.icon-tumblr-with-circle {
  &:before {
    content: $icon-tumblr-with-circle; 
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr; 
  }
}
.icon-twitter-with-circle {
  &:before {
    content: $icon-twitter-with-circle; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-vimeo-with-circle {
  &:before {
    content: $icon-vimeo-with-circle; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-vine-with-circle {
  &:before {
    content: $icon-vine-with-circle; 
  }
}
.icon-vine {
  &:before {
    content: $icon-vine; 
  }
}
.icon-vk-alternitive {
  &:before {
    content: $icon-vk-alternitive; 
  }
}
.icon-vk-with-circle {
  &:before {
    content: $icon-vk-with-circle; 
  }
}
.icon-vk {
  &:before {
    content: $icon-vk; 
  }
}
.icon-windows-store {
  &:before {
    content: $icon-windows-store; 
  }
}
.icon-xing-with-circle {
  &:before {
    content: $icon-xing-with-circle; 
  }
}
.icon-xing {
  &:before {
    content: $icon-xing; 
  }
}
.icon-yelp {
  &:before {
    content: $icon-yelp; 
  }
}
.icon-youko-with-circle {
  &:before {
    content: $icon-youko-with-circle; 
  }
}
.icon-youko {
  &:before {
    content: $icon-youko; 
  }
}
.icon-youtube-with-circle {
  &:before {
    content: $icon-youtube-with-circle; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}



/******/

[class^="logo-"],
[class*=" logo-"] {
  @include dsgl-logos-style;
  font-size: $icon-font-size;
}

.logo-desigual {
  &:before {
    content: $logo-desigual; 
  }
}