@charset "UTF-8";
/*
Starting point. Imports the rest of the elements.
To be imported in angular-cli styles.scss.
*/
/*
Site-wide variables. Includes app and 3rd party variables.
*/
/*border-color: $stateHighlightBorderColor;
background: $stateHighlightBgColor;
color: $stateHighlightTextColor;*/
/*
#8bb3c7
#005073
#ff9900
#43a8bd
#8bbe43*/
:root {
  --item-product-size: 50px;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
.button-trigger-close {
  color: #525a72;
  height: 69px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.button-trigger-close [class^=icon-] {
  font-size: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
.button-trigger-close .button-trigger-close-text {
  font-size: 1.5rem;
  font-family: "HelveticaNeueMedium";
  font-weight: bold;
}
.button-trigger-close:hover {
  background: #e1e9ec !important;
  border: 0 !important;
  color: currentColor !important;
}

.grid-length-0 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.grid-reference {
  font-family: "HelveticaNeueRoman";
  font-size: 0.6rem;
  text-transform: uppercase;
  color: #95a5ac;
  line-height: 1;
  /*.grid-group-header & {
      max-width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }*/
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 0s 0s forwards !important;
}

@keyframes animation-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@font-face {
  font-family: "HelveticaNeueRoman";
  src: url("helvetica-neue-roman.woff2") format("woff2"), url("helvetica-neue-roman.woff") format("woff");
}
@font-face {
  font-family: "HelveticaNeueMedium";
  src: url("helvetica-neue-medium.woff2") format("woff2"), url("helvetica-neue-medium.woff") format("woff");
}
@font-face {
  font-family: "Icomoon";
  src: url("icomoon.woff?luon03") format("woff"), url("icomoon.ttf?luon03") format("truetype");
}
@font-face {
  font-family: "Desigual-Logos";
  src: url("desigual-logos.woff?230517") format("woff"), url("desigual-logos.ttf?230517") format("truetype");
}
@font-face {
  font-family: "Custom";
  src: url("fjalla-one-v5-latin-regular.woff2") format("woff2"), url("fjalla-one-v5-latin-regular.woff") format("woff");
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e9ecef;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e9ecef;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "HelveticaNeueRoman", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #8bbe43;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #62862e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #042d36;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #868e96;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1320px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1320px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.25em + 1.4rem + 2px);
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #757575;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.form-control::placeholder {
  color: #ced4da;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.7rem + 1px);
  padding-bottom: calc(0.7rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: calc(0.9rem + 1px);
  padding-bottom: calc(0.9rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.44rem + 1px);
  padding-bottom: calc(0.44rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.7rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.88rem + 2px);
  padding: 0.44rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1.8rem + 2px);
  padding: 0.9rem 1.2rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #868e96;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #008E28;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 142, 40, 0.9);
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #008E28;
  padding-right: 2.65rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008E28' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.35rem) center;
  background-size: calc(0.625em + 0.7rem) calc(0.625em + 0.7rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #008E28;
  box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.65rem;
  background-position: top calc(0.3125em + 0.35rem) right calc(0.3125em + 0.35rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #008E28;
  padding-right: calc(0.75em + 3.05rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008E28' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.625em + 0.7rem) calc(0.625em + 0.7rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #008E28;
  box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #008E28;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #008E28;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #008E28;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00c136;
  background-color: #00c136;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #008E28;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008E28;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #008E28;
  box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #D72931;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(215, 41, 49, 0.9);
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #D72931;
  padding-right: 2.65rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D72931' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D72931' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.35rem) center;
  background-size: calc(0.625em + 0.7rem) calc(0.625em + 0.7rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #D72931;
  box-shadow: 0 0 0 0 rgba(215, 41, 49, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.65rem;
  background-position: top calc(0.3125em + 0.35rem) right calc(0.3125em + 0.35rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D72931;
  padding-right: calc(0.75em + 3.05rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D72931' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D72931' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.625em + 0.7rem) calc(0.625em + 0.7rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #D72931;
  box-shadow: 0 0 0 0 rgba(215, 41, 49, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D72931;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D72931;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #D72931;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #df545a;
  background-color: #df545a;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(215, 41, 49, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D72931;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D72931;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D72931;
  box-shadow: 0 0 0 0 rgba(215, 41, 49, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1320px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #8bbe43;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #000;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.8rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.9rem 1.2rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.88rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.44rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #8bbe43;
  background-color: #8bbe43;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #757575;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ddecc8;
  border-color: #ddecc8;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #8bbe43;
  background-color: #8bbe43;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(139, 190, 67, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(139, 190, 67, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(139, 190, 67, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(139, 190, 67, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.4rem + 2px);
  padding: 0.7rem 2rem 0.7rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #000;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #757575;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(139, 190, 67, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.88rem + 2px);
  padding-top: 0.44rem;
  padding-bottom: 0.44rem;
  padding-left: 0.7rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.8rem + 2px);
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 1.2rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.4rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.25em + 1.4rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #757575;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.25em + 1.4rem + 2px);
  padding: 0.7rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  border: 1px solid #000;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.65rem;
  padding: 0.7rem 1rem;
  line-height: 1.25;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #8bbe43;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #ddecc8;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #8bbe43;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #ddecc8;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #8bbe43;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #ddecc8;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #8bbe43;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1319.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1320px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.8rem;
}

.card-subtitle {
  margin-top: -0.4rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card-footer {
  padding: 0.8rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.8rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.8rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}
.accordion > .card > .card-header {
  margin-bottom: -1px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.badge-primary {
  color: #212529;
  background-color: #8bbe43;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #212529;
  background-color: #709935;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(139, 190, 67, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #1090C8;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #0c6e99;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(16, 144, 200, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #008E28;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #005b1a;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #43a8bd;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #358798;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(67, 168, 189, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #F0AD4E;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ec971f;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(240, 173, 78, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #D72931;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ad2027;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(215, 41, 49, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #fff;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.badge-dark {
  color: #212529;
  background-color: #A8A8A8;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #212529;
  background-color: #8f8f8f;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(168, 168, 168, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}
a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: black;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}
a.badge-white:hover, a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #8bbe43 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #709935 !important;
}

.bg-secondary {
  background-color: #1090C8 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0c6e99 !important;
}

.bg-success {
  background-color: #008E28 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005b1a !important;
}

.bg-info {
  background-color: #43a8bd !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #358798 !important;
}

.bg-warning {
  background-color: #F0AD4E !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ec971f !important;
}

.bg-danger {
  background-color: #D72931 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad2027 !important;
}

.bg-light {
  background-color: #fff !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #A8A8A8 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #8f8f8f !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #8bbe43 !important;
}

.border-secondary {
  border-color: #1090C8 !important;
}

.border-success {
  border-color: #008E28 !important;
}

.border-info {
  border-color: #43a8bd !important;
}

.border-warning {
  border-color: #F0AD4E !important;
}

.border-danger {
  border-color: #D72931 !important;
}

.border-light {
  border-color: #fff !important;
}

.border-dark {
  border-color: #A8A8A8 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0rem !important;
}

.rounded {
  border-radius: 0rem !important;
}

.rounded-top {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.rounded-right {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-left {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-lg {
  border-radius: 0rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1320px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1320px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1320px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1320px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1320px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #8bbe43 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #62862e !important;
}

.text-secondary {
  color: #1090C8 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #0a5d81 !important;
}

.text-success {
  color: #008E28 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #004212 !important;
}

.text-info {
  color: #43a8bd !important;
}

a.text-info:hover, a.text-info:focus {
  color: #2f7685 !important;
}

.text-warning {
  color: #F0AD4E !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #df8a13 !important;
}

.text-danger {
  color: #D72931 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #971c22 !important;
}

.text-light {
  color: #fff !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #A8A8A8 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #828282 !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

:root {
  --surface-a:#ffffff;
  --surface-b:#efefef;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#212529;
  --text-color-secondary:#6c757d;
  --primary-color:#007bff;
  --primary-color-text:#ffffff;
  --font-family:HelveticaNeueRoman;
  --surface-0: #ffffff;
  --surface-50: #f9fafb;
  --surface-100: #f8f9fa;
  --surface-200: #e9ecef;
  --surface-300: #dee2e6;
  --surface-400: #ced4da;
  --surface-500: #adb5bd;
  --surface-600: #6c757d;
  --surface-700: #495057;
  --surface-800: #343a40;
  --surface-900: #212529;
  --gray-50: #f9fafb;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --content-padding:1.25rem;
  --inline-spacing:0.5rem;
  --border-radius:0rem;
  --surface-ground:#efefef;
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dee2e6;
  --surface-hover:#e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #007bff;
  --highlight-text-color: #ffffff;
  --focus-ring: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  color-scheme: light;
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: "HelveticaNeueRoman";
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.15s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.35;
}

.p-error {
  color: #dc3545;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-icon {
  width: 1rem;
  height: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: "HelveticaNeueRoman";
  border-radius: 0rem;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

:root {
  --blue-50:#f9fcf6;
  --blue-100:#e3efd2;
  --blue-200:#cde3ae;
  --blue-300:#b7d78a;
  --blue-400:#a1ca67;
  --blue-500:#8bbe43;
  --blue-600:#76a239;
  --blue-700:#61852f;
  --blue-800:#4c6925;
  --blue-900:#384c1b;
  --indigo-50:#f7f3fe;
  --indigo-100:#dac6fc;
  --indigo-200:#bd98f9;
  --indigo-300:#a06bf7;
  --indigo-400:#833df4;
  --indigo-500:#6610f2;
  --indigo-600:#570ece;
  --indigo-700:#470ba9;
  --indigo-800:#380985;
  --indigo-900:#290661;
  --purple-50:#f8f6fc;
  --purple-100:#dcd2f0;
  --purple-200:#c1aee4;
  --purple-300:#a68ad9;
  --purple-400:#8a66cd;
  --purple-500:#6f42c1;
  --purple-600:#5e38a4;
  --purple-700:#4e2e87;
  --purple-800:#3d246a;
  --purple-900:#2c1a4d;
  --pink-50:#fef5f9;
  --pink-100:#f9d1e3;
  --pink-200:#f5acce;
  --pink-300:#f187b8;
  --pink-400:#ec63a2;
  --pink-500:#e83e8c;
  --pink-600:#c53577;
  --pink-700:#a22b62;
  --pink-800:#80224d;
  --pink-900:#5d1938;
  --red-50:#fdf4f5;
  --red-100:#f5ccce;
  --red-200:#eea3a6;
  --red-300:#e67a7f;
  --red-400:#df5258;
  --red-500:#d72931;
  --red-600:#b7232a;
  --red-700:#971d22;
  --red-800:#76171b;
  --red-900:#561014;
  --orange-50:#fff9f3;
  --orange-100:#ffe0c7;
  --orange-200:#fec89a;
  --orange-300:#feaf6d;
  --orange-400:#fd9741;
  --orange-500:#fd7e14;
  --orange-600:#d76b11;
  --orange-700:#b1580e;
  --orange-800:#8b450b;
  --orange-900:#653208;
  --yellow-50:#fefbf6;
  --yellow-100:#fbebd5;
  --yellow-200:#f9dcb3;
  --yellow-300:#f6cc91;
  --yellow-400:#f3bd70;
  --yellow-500:#f0ad4e;
  --yellow-600:#cc9342;
  --yellow-700:#a87937;
  --yellow-800:#845f2b;
  --yellow-900:#60451f;
  --green-50:#f2f9f4;
  --green-100:#c2e4cb;
  --green-200:#91cea3;
  --green-300:#61b97a;
  --green-400:#30a351;
  --green-500:#008e28;
  --green-600:#007922;
  --green-700:#00631c;
  --green-800:#004e16;
  --green-900:#003910;
  --teal-50:#f4fcfa;
  --teal-100:#c9f2e6;
  --teal-200:#9fe8d2;
  --teal-300:#75debf;
  --teal-400:#4ad3ab;
  --teal-500:#20c997;
  --teal-600:#1bab80;
  --teal-700:#168d6a;
  --teal-800:#126f53;
  --teal-900:#0d503c;
  --cyan-50:#f3f9fc;
  --cyan-100:#c6e4f2;
  --cyan-200:#98cfe7;
  --cyan-300:#6bbadd;
  --cyan-400:#3da5d2;
  --cyan-500:#1090c8;
  --cyan-600:#0e7aaa;
  --cyan-700:#0b658c;
  --cyan-800:#094f6e;
  --cyan-900:#063a50;
  --white-50:white;
  --white-100:white;
  --white-200:white;
  --white-300:white;
  --white-400:white;
  --white-500:white;
  --white-600:#d9d9d9;
  --white-700:#b3b3b3;
  --white-800:#8c8c8c;
  --white-900:#666666;
  --gray-50:#f9f9fa;
  --gray-100:#e2e4e6;
  --gray-200:#cbced2;
  --gray-300:#b4b9be;
  --gray-400:#9da3aa;
  --gray-500:#868e96;
  --gray-600:#727980;
  --gray-700:#5e6369;
  --gray-800:#4a4e53;
  --gray-900:#36393c;
  --gray-dark-50:whitesmoke;
  --gray-dark-100:#ced0d1;
  --gray-dark-200:#a8aaad;
  --gray-dark-300:#818589;
  --gray-dark-400:#5b5f64;
  --gray-dark-500:#343a40;
  --gray-dark-600:#2c3136;
  --gray-dark-700:#24292d;
  --gray-dark-800:#1d2023;
  --gray-dark-900:#15171a;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.107rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.75rem;
  gap: 0.5rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: "HelveticaNeueRoman";
  font-size: 1rem;
  color: #212529;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.75rem;
  background: #007bff;
  color: #ffffff;
  border-radius: 0rem;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #dc3545;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #212529;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: #495057;
  right: 0.75rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
  color: #495057;
  right: 3.107rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #dc3545;
}

.p-datepicker {
  padding: 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #efefef;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #212529;
  transition: box-shadow 0.15s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #007bff;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0rem;
  transition: box-shadow 0.15s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #212529;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #495057;
  right: 0.75rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #495057;
  right: 3.107rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1.5rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #dc3545;
}

.p-input-filled .p-cascadeselect {
  background: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: 0.75rem;
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
  box-shadow: none;
  border-radius: 0;
  padding: 0.25rem 0 0.25rem 0.5rem;
}
.p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
  transform: rotate(90deg);
}

.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 0rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.15s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box .p-icon {
  width: 14px;
  height: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007bff;
  background: #007bff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #dc3545;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #efefef;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #007bff;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #efefef;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0062cc;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
  gap: 0.5rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  background: #007bff;
  color: #ffffff;
  border-radius: 0rem;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "HelveticaNeueRoman";
  font-size: 1rem;
  color: #212529;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #dc3545;
}

p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.75rem;
}
p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #495057;
  right: 0.75rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #212529;
  border: 1px solid #212529;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: none;
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #dc3545;
}

.p-input-filled .p-dropdown {
  background: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
  background: #efefef;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 0rem;
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #212529;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #212529;
  background: #e9ecef;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1.5rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #007bff;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #007bff;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #007bff;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #007bff;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #222222;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #dc3545;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #495057;
  right: 0.75rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #dc3545;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #495057;
  right: 0.75rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
  right: 3.107rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
  right: 3.107rem;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 0rem;
  transition-duration: 0.15s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ced4da;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #007bff;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #007bff;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #dc3545;
}

.p-inputtext {
  font-family: "HelveticaNeueRoman";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  appearance: none;
  border-radius: 0rem;
}
.p-inputtext:enabled:hover {
  border-color: #ced4da;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #dc3545;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.15s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #dc3545;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: #495057;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #495057;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #efefef;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}
.p-listbox .p-listbox-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #dc3545;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-label {
  gap: 0.5rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  background: #007bff;
  color: #ffffff;
  border-radius: 0rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #dc3545;
}

.p-input-filled .p-multiselect {
  background: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #dc3545;
}

.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 0rem;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #e9ecef;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #dc3545;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #ffc107;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #28a745;
}

p-password.p-password-clearable .p-password-input {
  padding-right: 2.5rem;
}
p-password.p-password-clearable .p-password-clear-icon {
  color: #495057;
  right: 0.75rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 4.25rem;
}
p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #495057;
  right: 2.5rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 50%;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ced4da;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.15s;
  background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #007bff;
  background: #007bff;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #dc3545;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #007bff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #0062cc;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #ffffff;
}

.p-rating {
  gap: 0.5rem;
}
.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-icon {
  width: 1.143rem;
  height: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #dc3545;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.p-rating-icon-active {
  color: #007bff;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #007bff;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #dc3545;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #dc3545;
}

.p-slider {
  background: #e9ecef;
  border: 0 none;
  border-radius: 0rem;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #007bff;
  border: 2px solid #007bff;
  border-radius: 0rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-slider .p-slider-range {
  background: #007bff;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #0069d9;
  border-color: #0069d9;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, left 0.15s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, bottom 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.15s;
}

.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #dc3545;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #212529;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #dc3545;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 0.75rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.75rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.75rem;
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-button {
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.p-button:enabled:hover {
  background: #0069d9;
  color: #ffffff;
  border-color: #0069d9;
}
.p-button:enabled:active {
  background: #0062cc;
  color: #ffffff;
  border-color: #0062cc;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #007bff;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: #007bff;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: #007bff;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #007bff;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: #007bff;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: #007bff;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-button .p-button-label {
  transition-duration: 0.15s;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #007bff;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #6c757d;
  border: 1px solid #6c757d;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #5a6268;
  color: #ffffff;
  border-color: #5a6268;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #545b62;
  color: #ffffff;
  border-color: #4e555b;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #6c757d;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: transparent;
  color: #6c757d;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  border-color: transparent;
  color: #6c757d;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #17a2b8;
  border: 1px solid #17a2b8;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #138496;
  color: #ffffff;
  border-color: #117a8b;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #138496;
  color: #ffffff;
  border-color: #117a8b;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #17a2b8;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: transparent;
  color: #17a2b8;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  border-color: transparent;
  color: #17a2b8;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #28a745;
  border: 1px solid #28a745;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #218838;
  color: #ffffff;
  border-color: #1e7e34;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #1e7e34;
  color: #ffffff;
  border-color: #1c7430;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #28a745;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: transparent;
  color: #28a745;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  border-color: transparent;
  color: #28a745;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #e0a800;
  color: #212529;
  border-color: #d39e00;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #d39e00;
  color: #212529;
  border-color: #c69500;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #ffc107;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: transparent;
  color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  border-color: transparent;
  color: #ffc107;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #6f42c1;
  border: 1px solid #6f42c1;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #633bad;
  color: #ffffff;
  border-color: #58349a;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #58349a;
  color: #ffffff;
  border-color: #4d2e87;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #6f42c1;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: transparent;
  color: #6f42c1;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  border-color: transparent;
  color: #6f42c1;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #dc3545;
  border: 1px solid #dc3545;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c82333;
  color: #ffffff;
  border-color: #bd2130;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #bd2130;
  color: #ffffff;
  border-color: #b21f2d;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #dc3545;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: transparent;
  color: #dc3545;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  border-color: transparent;
  color: #dc3545;
}

.p-button.p-button-link {
  color: #007bff;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #0069d9;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #007bff;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-icon {
  width: 1.3rem;
  height: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: #fff;
}
.p-speeddial-action:hover {
  background: #343a40;
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-splitbutton {
  border-radius: 0rem;
}
.p-splitbutton.p-button-outlined > .p-button {
  background-color: transparent;
  color: #007bff;
  border: 1px solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: #007bff;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: #007bff;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #6c757d;
  border-color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-splitbutton.p-button-text > .p-button {
  background-color: transparent;
  color: #007bff;
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: #007bff;
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: #007bff;
  border-color: transparent;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-splitbutton.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}
.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}
.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}
.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: transparent;
  color: #6c757d;
  border-color: transparent;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: transparent;
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  border-color: transparent;
  color: #6c757d;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: transparent;
  color: #17a2b8;
  border: 1px solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: transparent;
  color: #17a2b8;
  border-color: transparent;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: transparent;
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  border-color: transparent;
  color: #17a2b8;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: transparent;
  color: #28a745;
  border: 1px solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: transparent;
  color: #28a745;
  border-color: transparent;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: transparent;
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  border-color: transparent;
  color: #28a745;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: transparent;
  color: #ffc107;
  border: 1px solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: transparent;
  color: #ffc107;
  border-color: transparent;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: transparent;
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  border-color: transparent;
  color: #ffc107;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: transparent;
  color: #6f42c1;
  border: 1px solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: transparent;
  color: #6f42c1;
  border-color: transparent;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: transparent;
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  border-color: transparent;
  color: #6f42c1;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: transparent;
  color: #dc3545;
  border-color: transparent;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: transparent;
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  border-color: transparent;
  color: #dc3545;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #007bff;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #212529;
  background: #ffffff;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #212529;
  background: #ffffff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #007bff;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #007bff;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #007bff;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #007bff;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #007bff;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #212529;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid rgba(38, 143, 255, 0.5);
  outline-offset: 0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #007bff;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #007bff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #007bff;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable .p-column-resizer-helper {
  background: #007bff;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #efefef;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: #ffffff;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #007bff;
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
}
.p-dataview .p-dataview-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1.25rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: transparent;
  color: #495057;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #007bff;
  color: #ffffff;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  box-shadow: none;
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}
.fc.fc-unthemed .fc-view-container th {
  background: #efefef;
  border: 1px solid #dee2e6;
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #0069d9;
  border: 1px solid #0069d9;
  color: #ffffff;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #efefef;
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #0069d9;
  color: #ffffff;
  border-color: #0069d9;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #0062cc;
  color: #ffffff;
  border-color: #0062cc;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #dee2e6;
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #efefef;
  border-color: #dee2e6;
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #212529;
  border-color: #dee2e6;
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #ffffff;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "\e90b";
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #ffffff;
  background: #0069d9;
  border-color: #0069d9;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #ffffff;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #0069d9;
  border-color: #0069d9;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: #e9ecef;
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #efefef;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 0rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #0069d9;
  color: #ffffff;
  border-color: #0069d9;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #0062cc;
  color: #ffffff;
  border-color: #0062cc;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.35;
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.fc.fc-theme-standard a {
  color: #212529;
}
.fc.fc-theme-standard .fc-highlight {
  color: #ffffff;
  background: #007bff;
}

.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem 1.25rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
  background: #e9ecef;
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: #212529;
  background: #ffffff;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #007bff;
  color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #003e80;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-paginator {
  background: #ffffff;
  color: #007bff;
  border: solid #dee2e6;
  border-width: 0;
  padding: 0.75rem;
  border-radius: 0rem;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #007bff;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #007bff;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2.357rem;
}
.p-paginator .p-paginator-current {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #007bff;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #007bff;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #007bff;
  border-color: #007bff;
  color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #007bff;
}

.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem 1.25rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
  background: #e9ecef;
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: #212529;
  background: #ffffff;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
}
.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 1.25rem;
  border-radius: 0rem;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 0rem;
  transition: box-shadow 0.15s;
  padding: 0.286rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #007bff;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #212529;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-tree-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #0062cc;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 0rem;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #212529;
  padding: 0.286rem;
  transition: box-shadow 0.15s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #007bff;
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #212529;
  background: #ffffff;
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #212529;
  background: #ffffff;
}
.p-treetable .p-sortable-column {
  outline-color: rgba(38, 143, 255, 0.5);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #007bff;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #007bff;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #007bff;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #212529;
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #212529;
}
.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid rgba(38, 143, 255, 0.5);
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #007bff;
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #212529;
}
.p-treetable .p-column-resizer-helper {
  background: #007bff;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #efefef;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable .p-treetable-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 0rem;
  transition: box-shadow 0.15s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #212529;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #efefef;
  border-color: #dee2e6;
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #212529;
}
.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-card {
  background: #ffffff;
  color: #212529;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0rem;
}
.p-card .p-card-body {
  padding: 1.5rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  border-radius: 0rem;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 0rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem 1.25rem;
  color: #212529;
  border-radius: 0rem;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #212529;
}
.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1.25rem;
}
.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  border-top: 0 none;
}
.p-panel .p-panel-icons-end {
  order: 2;
  margin-left: auto;
}
.p-panel .p-panel-icons-start {
  order: 0;
  margin-right: 0.5rem;
}
.p-panel .p-panel-icons-center {
  order: 2;
  width: 100%;
  text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #efefef;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 0rem;
  color: #212529;
}
.p-splitter .p-splitter-gutter {
  transition: box-shadow 0.15s;
  background: #efefef;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: transparent;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid;
  border-width: 1px;
  border-color: #ffffff #ffffff #dee2e6 #ffffff;
  background: #ffffff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #dee2e6;
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #495057;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #495057;
  width: 2.357rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #212529;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-toolbar {
  background: #efefef;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-radius: 0rem;
  gap: 0.5rem;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  box-shadow: none;
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-icon.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 0rem;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #e9ecef;
  background: #ffffff;
  color: #212529;
  padding: 1rem;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #212529;
  padding: 1rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #e9ecef;
  background: #ffffff;
  color: #212529;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  box-shadow: none;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #007bff;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #0069d9;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}

.p-sidebar {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
.p-sidebar .p-sidebar-header {
  padding: 1rem 1.25rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1.25rem;
}
.p-sidebar .p-sidebar-footer {
  padding: 1rem 1.25rem;
}

.p-tooltip .p-tooltip-text {
  background: #212529;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  border-radius: 0rem;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #212529;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #212529;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #212529;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #212529;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #efefef;
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom: 0 none;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #0069d9;
  color: #ffffff;
  border-color: #0069d9;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #0062cc;
  color: #ffffff;
  border-color: #0062cc;
}

.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 0rem;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #007bff;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #007bff;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 0rem;
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 0rem;
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-contextmenu .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-contextmenu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 0rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0rem;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}
.p-menu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menubar {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 0rem;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0rem;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: box-shadow 0.15s;
  }
  .p-menubar .p-menubar-button:hover {
    color: rgba(0, 0, 0, 0.7);
    background: transparent;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.15s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.15s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 0rem;
  transition: box-shadow 0.15s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #212529;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #efefef;
  border-color: #dee2e6;
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #212529;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.p-panelmenu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-slidemenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #212529;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #212529;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #212529;
}
.p-slidemenu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
  background: transparent;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #212529;
  border: 1px solid #dee2e6;
  background: transparent;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 0rem;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #007bff;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #212529;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid;
  border-width: 1px;
  border-color: #ffffff #ffffff #dee2e6 #ffffff;
  background: #ffffff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6;
  color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #495057;
}
.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: #ffffff;
  color: #495057;
  width: 2.357rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-tieredmenu .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-tieredmenu .p-menuitem-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 0rem;
}
.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 0px;
  color: #004085;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #004085;
}
.p-inline-message.p-inline-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 0px;
  color: #155724;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #155724;
}
.p-inline-message.p-inline-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 0px;
  color: #856404;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #856404;
}
.p-inline-message.p-inline-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 0px;
  color: #721c24;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #721c24;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-icon {
  width: 1rem;
  height: 1rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 0rem;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.25rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: box-shadow 0.15s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-message.p-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-message.p-message-info .p-message-icon {
  color: #004085;
}
.p-message.p-message-info .p-message-close {
  color: #004085;
}
.p-message.p-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-message.p-message-success .p-message-icon {
  color: #155724;
}
.p-message.p-message-success .p-message-close {
  color: #155724;
}
.p-message.p-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-message.p-message-warn .p-message-icon {
  color: #856404;
}
.p-message.p-message-warn .p-message-close {
  color: #856404;
}
.p-message.p-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-message.p-message-error .p-message-icon {
  color: #721c24;
}
.p-message.p-message-error .p-message-close {
  color: #721c24;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0rem;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
  width: 2rem;
  height: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: box-shadow 0.15s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #721c24;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
  width: 2rem;
  height: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
  width: 2rem;
  height: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #efefef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #007bff;
  color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #007bff;
  color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #efefef;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 0rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: box-shadow 0.15s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}
.p-image-action.p-link .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 0rem;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #6c757d;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #28a745;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #17a2b8;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #dc3545;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: #dee2e6;
  color: #212529;
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 0rem;
  transition: box-shadow 0.15s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.75rem;
  border-radius: 0rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #212529;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-progressbar {
  height: 0.625rem;
  background: #42436a;
  border-radius: 0rem;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #ff7676;
}
.p-progressbar .p-progressbar-label {
  color: #ffffff;
  line-height: 0.625rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 0rem;
  box-shadow: none;
  transition: box-shadow 0.15s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #efefef;
}
.p-scrolltop .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 0rem;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 0rem;
}
.p-tag.p-tag-success {
  background-color: #28a745;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #dc3545;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}
.p-tag .p-icon {
  width: 0.75rem;
  height: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: "HelveticaNeueRoman";
}

/* Vendor extensions to the designer enhanced bootstrap compatibility */
.p-breadcrumb .p-breadcrumb-chevron {
  font-family: "HelveticaNeueRoman";
}
.p-breadcrumb .p-breadcrumb-chevron:before {
  content: "/";
}

.fc.fc-theme-standard .fc-highlight {
  background: #cce5ff;
}

/* Customizations to the designer theme should be defined here */
.p-button:focus {
  box-shadow: none;
}

.p-button.p-togglebutton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  border: 0;
  background: transparent !important;
}
.p-button.p-togglebutton.p-state-active {
  background-color: #2d3a64;
}
.p-button.p-togglebutton.p-state-active .p-button-text:after {
  color: #525a72;
  top: 9px;
  left: -1px;
  font-size: 20px;
}

.p-togglebutton.p-button:not(.p-disabled).p-highlight,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background-color: #2d3a64 !important;
}

.ui-button.button-kpi,
.ui-button.orders-group {
  padding: 0.7rem 1.5rem;
  color: #ffffff;
}

.btn-refresh.rounded-circle .icon-cycle {
  margin-top: 10px;
}

.p-dialog {
  width: 98%;
  max-width: 1420px;
}
.p-dialog .p-dialog-header {
  padding: 0.4rem !important;
  border-bottom: 0 !important;
}

.p-dialog.p-widget-content,
.p-dialog .p-widget-content {
  background: white;
}
.p-dialog.p-widget-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-dialog.p-shadow {
  box-shadow: none;
}
.p-dialog .p-widget-header {
  background-color: transparent;
  text-transform: inherit;
}
.p-dialog .p-dialog-title {
  font-size: 1.75rem;
}
.p-dialog .p-dialog-titlebar,
.p-dialog .p-dialog-content,
.p-dialog .p-dialog-footer {
  padding: 1rem !important;
}
.p-dialog .p-dialog-content {
  font-size: 1.125rem;
}
.p-dialog .p-dialog-titlebar-close {
  font-size: 1.5rem;
  border: none;
  position: absolute;
  float: none;
  top: 1rem;
  right: 0;
  z-index: 1;
}
.p-dialog .p-dialog-titlebar-icon:hover {
  border-color: transparent;
  background: transparent;
  color: #4D4D4D;
}
.p-dialog .p-widget-content {
  border: none;
}
.p-dialog .p-button + .p-button {
  margin-left: 0.5em;
}

@media (max-width: 767.98px) {
  .p-dialog {
    width: 100% !important;
    height: 90vh;
  }
}
.p-progressbar {
  border: 0;
}

.p-sidebar-right {
  width: 175px !important;
  background-color: #fafcfc;
  border: 0;
  border-left: 1px solid #d7e2e6;
  padding: 0;
}
.p-sidebar-right .button-trigger-close {
  padding: 0 1.5rem;
}
.p-sidebar-right .p-sidebar-content {
  padding: 0;
}
.p-sidebar-right .p-sidebar-content .ui-sidebar-wrapper {
  padding: 0 1rem;
}
.p-sidebar-right .p-sidebar-header,
.p-sidebar-right .p-sidebar-footer {
  display: none;
}

table .table-group {
  width: 100%;
}
table .table-group tr td {
  width: 24%;
}
table .table-group .ng-star-inserted {
  padding: 1em 1em;
}

.p-datatable .p-datatable-tbody > tr > td {
  width: 24%;
}

.fa-check {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-check:before {
  content: "\e948";
}

.fa-close {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-close:before {
  content: "\e97d";
}

.fa-info-circle {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-info-circle:before {
  content: "\e90c";
}

.fa-warning,
.fa-exclamation-circle {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-warning:before,
.fa-exclamation-circle:before {
  content: "\e90b";
}

.fa-sign-out {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-sign-out:before {
  content: "\e9d7";
}

.fa-calendar {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-calendar:before {
  content: "\e944";
}

.fa-search {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-search:before {
  content: "\e9db";
}

.fa-print {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-print:before {
  content: "\ea03";
}

.fa-user,
.fa-user-o {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-user:before,
.fa-user-o:before {
  content: "\ea4d";
}

.fa-navicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-navicon:before {
  content: "\e9e2";
}

.fa-refresh {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-refresh:before {
  content: "\e980";
}

.fa-trash {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-trash:before {
  content: "\ea3f";
}

.fa-edit {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-edit:before {
  content: "\e990";
}

.fa-plus {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-plus:before {
  content: "\e9fe";
}

.fa-question-circle {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-question-circle:before {
  content: "\e9b6";
}

@keyframes ui-messages-enter {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.ui-messages {
  margin-top: 0;
  padding: 1rem 1.5rem;
  position: relative;
  animation: ui-messages-enter 0.3s cubic-bezier(0.02, 0.65, 0, 1);
}
.ui-messages .ui-messages-close {
  top: 10px;
  right: 10px;
  color: inherit;
}
.ui-messages .ui-messages-icon {
  border: 1px solid currentColor;
  border-radius: 50%;
  width: auto;
  margin-right: 0.7em;
  padding: 0.3em;
  font-size: 1.5em;
}
.ui-messages .ui-messages-icon:before {
  width: 1em;
  display: inline-block;
  text-align: center;
}
.ui-messages .icon-circle-dgl {
  align-self: flex-start;
  font-size: inherit;
  margin-top: -0.3rem;
}
.ui-messages .ui-messages-summary,
.ui-messages .ui-messages-detail {
  display: block;
}
.ui-messages .ui-messages-summary {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
}
.ui-messages-success {
  color: #23550C;
  background-color: #CDECAE;
  border-color: #CDECAE;
}

.ui-messages-info {
  color: #0077CB;
  background-color: #B6DDF2;
  border-color: #B6DDF2;
}

.ui-messages-warn {
  color: #704F17;
  background-color: #F9E0A7;
  border-color: #F9E0A7;
}

.ui-messages-error {
  color: #471111;
  background-color: #F8D7DA;
  border-color: #F8D7DA;
}

p-message .ui-messages-success,
p-message .ui-messages-info,
p-message .ui-messages-warn,
p-message .ui-messages-error {
  background-color: transparent;
  border-color: transparent;
}
p-message .ui-messages-error {
  color: #D72931;
}

h1 {
  font-weight: normal;
}

h2 {
  font-family: "HelveticaNeueRoman";
  font-weight: normal;
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

h4 {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

/*
Utils
*/
.text-center {
  text-align: center;
}

.text-none {
  text-transform: none !important;
}

.click {
  cursor: pointer;
}

.bg-green {
  background-color: #008E28;
}

.bg-red {
  background-color: #D72931;
}

.bg-gray-light {
  background-color: #F8F8F8;
}

.bg-gray-mid {
  background-color: #F2F2F2;
}

.bg-gray {
  background-color: #E6E6E6;
}

.bg-gray-dark {
  background-color: #A8A8A8;
}

.color-group-0100 {
  background-color: rgb(240, 240, 240);
}

.color-group-0200 {
  background-color: rgb(53, 48, 47);
}

.color-group-0300 {
  background-color: rgb(216, 30, 5);
}

.color-group-0400 {
  background-color: rgb(39, 174, 96);
}

.color-group-0500 {
  background-color: rgb(0, 91, 153);
}

.color-group-0600 {
  background-color: rgb(96, 51, 10);
}

.color-group-0700 {
  background-color: rgb(242, 132, 17);
}

.color-group-0800 {
  background-color: rgb(255, 237, 56);
}

.color-group-0900 {
  background-color: rgb(255, 255, 255);
}

.dropdown {
  display: inline-block;
  z-index: auto !important;
}
.dropdown.show {
  z-index: 2 !important;
}
.dropdown [class*=btn-outline]:after {
  color: inherit;
}

.ng-dirty .dropdown-toggle {
  background-color: #F8F8F8;
}
.ng-dirty .dropdown-toggle [class*=btn-outline]:hover {
  color: #000 !important;
}

.is-submitted.ng-invalid .dropdown-toggle:not(:hover, :focus) {
  border-color: #D72931;
}
.is-submitted.ng-invalid .dropdown-toggle:not(:hover, :focus):after {
  color: #D72931;
}

.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: left;
  box-shadow: none !important;
  border-color: #000;
  padding-right: 3.3rem;
}
.dropdown-toggle:after {
  display: flex;
  align-items: center;
  font-family: "Icomoon";
  content: "\e949";
  font-size: 1.25rem;
  width: 1em;
  height: 100%;
  line-height: 1;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  transform-origin: 50% 50% 0;
  transition: transform 0.15s;
}
.dropdown-toggle.btn-sm, .btn-group-sm > .dropdown-toggle.btn {
  padding: 0.44rem 3.2rem 0.44rem 0.8rem;
}
.dropdown-toggle.btn-sm:after, .btn-group-sm > .dropdown-toggle.btn:after {
  right: 0.7rem;
}
.dropdown-toggle.btn-lg, .btn-group-lg > .dropdown-toggle.btn {
  padding-right: 3.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-toggle.btn-lg:after, .btn-group-lg > .dropdown-toggle.btn:after {
  right: 1rem;
}
.show .dropdown-toggle:after {
  transform: rotate(180deg);
}

.dropdown-menu.show {
  font-size: 1rem;
  min-width: 0;
}
.dropdown-item {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  background-color: #FFF;
  transition: none;
}
.dropdown-item:first-of-type {
  border-top: 1px solid #000;
}
.dropdown-item:focus {
  outline: none;
}
.dropdown-item:hover {
  background-color: #8bbe43;
  color: #FFF;
}

@media (max-width: 767.98px) {
  .dropdown:after {
    opacity: 0;
    transition: transform 0.3s ease-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .dropdown:after {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .dropdown.show:after {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0.5;
  }
}
@media (max-width: 767.98px) {
  .dropdown-menu.show {
    position: fixed !important;
    top: 50% !important;
    left: 2em !important;
    z-index: 1051;
    right: 2em !important;
    transform: translateY(-50%) !important;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    border: 1px solid #000;
  }
}
@media (min-width: 768px) {
  .show .dropdown-toggle {
    z-index: 1;
    background-color: transparent !important;
    color: #495057;
    border-color: transparent !important;
  }
  .show .dropdown-toggle:before {
    color: #4D4D4D;
  }
  .dropdown-menu {
    transform: none !important;
  }
  .dropdown-menu.show {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding: calc(1.25em + 1.4rem + 2px) 1px 1px 1px;
    margin: 0 0 1em 0;
    z-index: 0;
    border: 1px solid #4D4D4D;
    box-shadow: 0px 0px 0px 0px;
  }
  .btn-sm + .dropdown-menu.show, .btn-group-sm > .btn + .dropdown-menu.show {
    padding: calc(1.5em + 0.88rem + 2px) 0 0 0;
  }
  .btn-sm + .dropdown-menu.show .dropdown-item, .btn-group-sm > .btn + .dropdown-menu.show .dropdown-item {
    padding: 0.25rem 0.8rem;
  }
  .btn-lg + .dropdown-menu.show, .btn-group-lg > .btn + .dropdown-menu.show {
    padding: 3.3em 0 0 0;
  }
  .dropdown-item {
    font-size: 0.8em;
  }
}
/* Spinner - Loading*/
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
[class*=dglSpinner]:before {
  content: "";
  display: block;
  position: absolute;
  height: 40px;
  width: 40px;
  top: calc(50% - (40px/2));
  left: calc(50% - (40px/2));
  border-radius: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.3);
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid #000;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  animation: rotation 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform-origin: center center;
  z-index: 1039;
}
[class*=dglSpinner]:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.5s ease;
  top: 0;
  left: 0;
  z-index: 21;
}
[class*=dglSpinner].spinner-layout:before, [class*=dglSpinner].spinner-layout:after {
  position: fixed;
}
[class*=dglSpinner][class*=-top]:before {
  top: 2rem;
}
[class*=dglSpinner].btn {
  background-color: #8bbe43;
  border-color: #8bbe43;
  color: #8bbe43;
  cursor: default;
}
[class*=dglSpinner].btn:before {
  height: 1em;
  width: 1em;
  top: calc(50% - (1em/2));
  left: calc(50% - (1em/2));
  border-radius: 100%;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid #FFF;
  border-right: 2px solid rgba(255, 255, 255, 0.3);
  animation: rotation 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform-origin: center center;
  z-index: 1039;
}
[class*=dglSpinner].btn:after {
  display: none;
}
[class*=dglSpinner].btn[class*=-left] {
  color: #FFF;
}
[class*=dglSpinner].btn[class*=-left]:before {
  position: relative;
  display: inline-block;
  left: -0.46em;
  top: 0.1725em;
  margin-left: 0.46em;
}

.icon,
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}

.icon-circle-dgl {
  display: inline-block;
  border: 0.05rem solid currentColor;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  flex-shrink: 0;
  align-self: center;
  line-height: 2em !important;
}
.icon-circle-dgl:before {
  font-size: 1.2em;
}
.icon-circle-dgl.icon-close-dgl:before {
  font-size: 1em;
}

.icon-circle-dgl-xs {
  font-size: 0.75rem;
}

.icon-chevron-double-left:before {
  content: "\ead5";
}

.icon-chevron-double-right:before {
  content: "\ead6";
}

.icon-logo-mydesigual:before {
  content: "\ead4";
}

.icon-product:before {
  content: "\ead2";
}

.icon-table:before {
  content: "\ead3";
}

.icon-magnifying-glass-plus:before {
  content: "\eab0";
}

.icon-magnifying-glass-minus:before {
  content: "\eab1";
}

.icon-sound-muted-bigger:before {
  content: "\eab2";
}

.icon-sound-muted-medium:before {
  content: "\eab3";
}

.icon-sound-bigger:before {
  content: "\eab4";
}

.icon-sound-medium:before {
  content: "\eab5";
}

.icon-sent:before {
  content: "\eab6";
}

.icon-returned:before {
  content: "\eab7";
}

.icon-help-with-circle-black:before {
  content: "\eab8";
}

.icon-preparing:before {
  content: "\eab9";
}

.icon-play-bigger:before {
  content: "\eaba";
}

.icon-play-medium:before {
  content: "\eabb";
}

.icon-location-pin-black:before {
  content: "\eabc";
}

.icon-emoji-surprised:before {
  content: "\eabd";
}

.icon-pick:before {
  content: "\eabe";
}

.icon-pause-bigger:before {
  content: "\eabf";
}

.icon-pause-medium:before {
  content: "\eac0";
}

.icon-partially-returned:before {
  content: "\eac1";
}

.icon-online:before {
  content: "\eac2";
}

.icon-magic:before {
  content: "\eac3";
}

.icon-magic-three:before {
  content: "\eac4";
}

.icon-magic-two:before {
  content: "\eac5";
}

.icon-magic-one:before {
  content: "\eac6";
}

.icon-magic-logo:before {
  content: "\eac7";
}

.icon-linear-line:before {
  content: "\eac8";
}

.icon-heart-break-right:before {
  content: "\eac9";
}

.icon-heart-break-left:before {
  content: "\eaca";
}

.icon-filter-sort:before {
  content: "\eacb";
}

.icon-check-small:before {
  content: "\eacc";
}

.icon-check-bubble:before {
  content: "\eacd";
}

.icon-check-bubble-black:before {
  content: "\eace";
}

.icon-canceled:before {
  content: "\eacf";
}

.icon-alert:before {
  content: "\ead0";
}

.icon-360:before {
  content: "\ead1";
}

.icon-user-mask-dgl:before {
  content: "\eaac";
}

.icon-user-dgl:before {
  content: "\eaad";
}

.icon-cloud-upload-dgl:before {
  content: "\eaae";
}

.icon-mask-batman-dgl:before {
  content: "\eaaf";
}

.icon-home-dgl:before {
  content: "\eaaa";
}

.icon-mail-dgl:before {
  content: "\eaab";
}

.icon-scan-dgl:before {
  content: "\e900";
}

.icon-logodesigual:before {
  content: "\eaa9";
}

.icon-bin-dgl:before {
  content: "\e901";
}

.icon-cart-dgl:before {
  content: "\e902";
}

.icon-close-dgl:before {
  content: "\e903";
}

.icon-up-dgl:before {
  content: "\e904";
}

.icon-down-dgl:before {
  content: "\e905";
}

.icon-back-dgl:before {
  content: "\e906";
}

.icon-forward-dgl:before {
  content: "\e907";
}

.icon-sign-minus-dgl:before {
  content: "\e908";
}

.icon-sign-plus-dgl:before {
  content: "\e909";
}

.icon-search-dgl:before {
  content: "\e90a";
}

.icon-sign-warning-dgl:before {
  content: "\e90b";
}

.icon-info-dgl:before {
  content: "\e90c";
}

.icon-check-dgl:before {
  content: "\e90d";
}

.icon-add-to-list:before {
  content: "\e90e";
}

.icon-add-user:before {
  content: "\e90f";
}

.icon-address:before {
  content: "\e910";
}

.icon-adjust:before {
  content: "\e911";
}

.icon-air:before {
  content: "\e912";
}

.icon-aircraft-landing:before {
  content: "\e913";
}

.icon-aircraft-take-off:before {
  content: "\e914";
}

.icon-aircraft:before {
  content: "\e915";
}

.icon-align-bottom:before {
  content: "\e916";
}

.icon-align-horizontal-middle:before {
  content: "\e917";
}

.icon-align-left:before {
  content: "\e918";
}

.icon-align-right:before {
  content: "\e919";
}

.icon-align-top:before {
  content: "\e91a";
}

.icon-align-vertical-middle:before {
  content: "\e91b";
}

.icon-archive:before {
  content: "\e91c";
}

.icon-area-graph:before {
  content: "\e91d";
}

.icon-arrow-bold-down:before {
  content: "\e91e";
}

.icon-arrow-bold-left:before {
  content: "\e91f";
}

.icon-arrow-bold-right:before {
  content: "\e920";
}

.icon-arrow-bold-up:before {
  content: "\e921";
}

.icon-arrow-down:before {
  content: "\e922";
}

.icon-arrow-left:before {
  content: "\e923";
}

.icon-arrow-long-down:before {
  content: "\e924";
}

.icon-arrow-long-left:before {
  content: "\e925";
}

.icon-arrow-long-right:before {
  content: "\e926";
}

.icon-arrow-long-up:before {
  content: "\e927";
}

.icon-arrow-right:before {
  content: "\e928";
}

.icon-arrow-up:before {
  content: "\e929";
}

.icon-arrow-with-circle-down:before {
  content: "\e92a";
}

.icon-arrow-with-circle-left:before {
  content: "\e92b";
}

.icon-arrow-with-circle-right:before {
  content: "\e92c";
}

.icon-arrow-with-circle-up:before {
  content: "\e92d";
}

.icon-attachment:before {
  content: "\e92e";
}

.icon-awareness-ribbon:before {
  content: "\e92f";
}

.icon-back-in-time:before {
  content: "\e930";
}

.icon-back:before {
  content: "\e931";
}

.icon-bar-graph:before {
  content: "\e932";
}

.icon-battery:before {
  content: "\e933";
}

.icon-beamed-note:before {
  content: "\e934";
}

.icon-bell:before {
  content: "\e935";
}

.icon-blackboard:before {
  content: "\e936";
}

.icon-block:before {
  content: "\e937";
}

.icon-book:before {
  content: "\e938";
}

.icon-bookmark:before {
  content: "\e939";
}

.icon-bookmarks:before {
  content: "\e93a";
}

.icon-bowl:before {
  content: "\e93b";
}

.icon-box:before {
  content: "\e93c";
}

.icon-briefcase:before {
  content: "\e93d";
}

.icon-browser:before {
  content: "\e93e";
}

.icon-brush:before {
  content: "\e93f";
}

.icon-bucket:before {
  content: "\e940";
}

.icon-bug:before {
  content: "\e941";
}

.icon-cake:before {
  content: "\e942";
}

.icon-calculator:before {
  content: "\e943";
}

.icon-calendar:before {
  content: "\e944";
}

.icon-camera:before {
  content: "\e945";
}

.icon-ccw:before {
  content: "\e946";
}

.icon-chat:before {
  content: "\e947";
}

.icon-check:before {
  content: "\e948";
}

.icon-chevron-down:before {
  content: "\e949";
}

.icon-chevron-left:before {
  content: "\e94a";
}

.icon-chevron-right:before {
  content: "\e94b";
}

.icon-chevron-small-down:before {
  content: "\e94c";
}

.icon-chevron-small-left:before {
  content: "\e94d";
}

.icon-chevron-small-right:before {
  content: "\e94e";
}

.icon-chevron-small-up:before {
  content: "\e94f";
}

.icon-chevron-thin-down:before {
  content: "\e950";
}

.icon-chevron-thin-left:before {
  content: "\e951";
}

.icon-chevron-thin-right:before {
  content: "\e952";
}

.icon-chevron-thin-up:before {
  content: "\e953";
}

.icon-chevron-up:before {
  content: "\e954";
}

.icon-chevron-with-circle-down:before {
  content: "\e955";
}

.icon-chevron-with-circle-left:before {
  content: "\e956";
}

.icon-chevron-with-circle-right:before {
  content: "\e957";
}

.icon-chevron-with-circle-up:before {
  content: "\e958";
}

.icon-circle-with-cross:before {
  content: "\e959";
}

.icon-circle-with-minus:before {
  content: "\e95a";
}

.icon-circle-with-plus:before {
  content: "\e95b";
}

.icon-circle:before {
  content: "\e95c";
}

.icon-circular-graph:before {
  content: "\e95d";
}

.icon-clapperboard:before {
  content: "\e95e";
}

.icon-classic-computer:before {
  content: "\e95f";
}

.icon-clipboard:before {
  content: "\e960";
}

.icon-clock:before {
  content: "\e961";
}

.icon-cloud:before {
  content: "\e962";
}

.icon-code:before {
  content: "\e963";
}

.icon-cog:before {
  content: "\e964";
}

.icon-colours:before {
  content: "\e965";
}

.icon-compass:before {
  content: "\e966";
}

.icon-controller-fast-backward:before {
  content: "\e967";
}

.icon-controller-fast-forward:before {
  content: "\e968";
}

.icon-controller-jump-to-start:before {
  content: "\e969";
}

.icon-controller-next:before {
  content: "\e96a";
}

.icon-controller-paus:before {
  content: "\e96b";
}

.icon-controller-play:before {
  content: "\e96c";
}

.icon-controller-record:before {
  content: "\e96d";
}

.icon-controller-stop:before {
  content: "\e96e";
}

.icon-controller-volume:before {
  content: "\e96f";
}

.icon-copy:before {
  content: "\e970";
}

.icon-creative-commons-attribution:before {
  content: "\e971";
}

.icon-creative-commons-noderivs:before {
  content: "\e972";
}

.icon-creative-commons-noncommercial-eu:before {
  content: "\e973";
}

.icon-creative-commons-noncommercial-us:before {
  content: "\e974";
}

.icon-creative-commons-public-domain:before {
  content: "\e975";
}

.icon-creative-commons-remix:before {
  content: "\e976";
}

.icon-creative-commons-share:before {
  content: "\e977";
}

.icon-creative-commons-sharealike:before {
  content: "\e978";
}

.icon-creative-commons:before {
  content: "\e979";
}

.icon-credit-card:before {
  content: "\e97a";
}

.icon-credit:before {
  content: "\e97b";
}

.icon-crop:before {
  content: "\e97c";
}

.icon-cross:before {
  content: "\e97d";
}

.icon-cup:before {
  content: "\e97e";
}

.icon-cw:before {
  content: "\e97f";
}

.icon-cycle:before {
  content: "\e980";
}

.icon-database:before {
  content: "\e981";
}

.icon-dial-pad:before {
  content: "\e982";
}

.icon-direction:before {
  content: "\e983";
}

.icon-document-landscape:before {
  content: "\e984";
}

.icon-document:before {
  content: "\e985";
}

.icon-documents:before {
  content: "\e986";
}

.icon-dot-single:before {
  content: "\e987";
}

.icon-dots-three-horizontal:before {
  content: "\e988";
}

.icon-dots-three-vertical:before {
  content: "\e989";
}

.icon-dots-two-horizontal:before {
  content: "\e98a";
}

.icon-dots-two-vertical:before {
  content: "\e98b";
}

.icon-download:before {
  content: "\e98c";
}

.icon-drink:before {
  content: "\e98d";
}

.icon-drive:before {
  content: "\e98e";
}

.icon-drop:before {
  content: "\e98f";
}

.icon-edit:before {
  content: "\e990";
}

.icon-email:before {
  content: "\e991";
}

.icon-emoji-flirt:before {
  content: "\e992";
}

.icon-emoji-happy:before {
  content: "\e993";
}

.icon-emoji-neutral:before {
  content: "\e994";
}

.icon-emoji-sad:before {
  content: "\e995";
}

.icon-erase:before {
  content: "\e996";
}

.icon-eraser:before {
  content: "\e997";
}

.icon-export:before {
  content: "\e998";
}

.icon-eye-with-line:before {
  content: "\e999";
}

.icon-eye:before {
  content: "\e99a";
}

.icon-feather:before {
  content: "\e99b";
}

.icon-fingerprint:before {
  content: "\e99c";
}

.icon-flag:before {
  content: "\e99d";
}

.icon-flash:before {
  content: "\e99e";
}

.icon-flashlight:before {
  content: "\e99f";
}

.icon-flat-brush:before {
  content: "\e9a0";
}

.icon-flow-branch:before {
  content: "\e9a1";
}

.icon-flow-cascade:before {
  content: "\e9a2";
}

.icon-flow-line:before {
  content: "\e9a3";
}

.icon-flow-parallel:before {
  content: "\e9a4";
}

.icon-flow-tree:before {
  content: "\e9a5";
}

.icon-flower:before {
  content: "\e9a6";
}

.icon-folder-images:before {
  content: "\e9a7";
}

.icon-folder-music:before {
  content: "\e9a8";
}

.icon-folder-video:before {
  content: "\e9a9";
}

.icon-folder:before {
  content: "\e9aa";
}

.icon-forward:before {
  content: "\e9ab";
}

.icon-funnel:before {
  content: "\e9ac";
}

.icon-game-controller:before {
  content: "\e9ad";
}

.icon-gauge:before {
  content: "\e9ae";
}

.icon-globe:before {
  content: "\e9af";
}

.icon-graduation-cap:before {
  content: "\e9b0";
}

.icon-grid:before {
  content: "\e9b1";
}

.icon-hair-cross:before {
  content: "\e9b2";
}

.icon-hand:before {
  content: "\e9b3";
}

.icon-heart-outlined:before {
  content: "\e9b4";
}

.icon-heart:before {
  content: "\e9b5";
}

.icon-help-with-circle:before {
  content: "\e9b6";
}

.icon-help:before {
  content: "\e9b7";
}

.icon-home:before {
  content: "\e9b8";
}

.icon-hour-glass:before {
  content: "\e9b9";
}

.icon-image-inverted:before {
  content: "\e9ba";
}

.icon-image:before {
  content: "\e9bb";
}

.icon-images:before {
  content: "\e9bc";
}

.icon-inbox:before {
  content: "\e9bd";
}

.icon-infinity:before {
  content: "\e9be";
}

.icon-info-with-circle:before {
  content: "\e9bf";
}

.icon-info:before {
  content: "\e9c0";
}

.icon-install:before {
  content: "\e9c1";
}

.icon-key:before {
  content: "\e9c2";
}

.icon-keyboard:before {
  content: "\e9c3";
}

.icon-lab-flask:before {
  content: "\e9c4";
}

.icon-landline:before {
  content: "\e9c5";
}

.icon-language:before {
  content: "\e9c6";
}

.icon-laptop:before {
  content: "\e9c7";
}

.icon-layers:before {
  content: "\e9c8";
}

.icon-leaf:before {
  content: "\e9c9";
}

.icon-level-down:before {
  content: "\e9ca";
}

.icon-level-up:before {
  content: "\e9cb";
}

.icon-lifebuoy:before {
  content: "\e9cc";
}

.icon-light-bulb:before {
  content: "\e9cd";
}

.icon-light-down:before {
  content: "\e9ce";
}

.icon-light-up:before {
  content: "\e9cf";
}

.icon-line-graph:before {
  content: "\e9d0";
}

.icon-link:before {
  content: "\e9d1";
}

.icon-list:before {
  content: "\e9d2";
}

.icon-location-pin:before {
  content: "\e9d3";
}

.icon-location:before {
  content: "\e9d4";
}

.icon-lock-open:before {
  content: "\e9d5";
}

.icon-lock:before {
  content: "\e9d6";
}

.icon-log-out:before {
  content: "\e9d7";
}

.icon-login:before {
  content: "\e9d8";
}

.icon-loop:before {
  content: "\e9d9";
}

.icon-magnet:before {
  content: "\e9da";
}

.icon-magnifying-glass:before {
  content: "\e9db";
}

.icon-mail:before {
  content: "\e9dc";
}

.icon-man:before {
  content: "\e9dd";
}

.icon-map:before {
  content: "\e9de";
}

.icon-mask:before {
  content: "\e9df";
}

.icon-medal:before {
  content: "\e9e0";
}

.icon-megaphone:before {
  content: "\e9e1";
}

.icon-menu:before {
  content: "\e9e2";
}

.icon-merge:before {
  content: "\e9e3";
}

.icon-message:before {
  content: "\e9e4";
}

.icon-mic:before {
  content: "\e9e5";
}

.icon-minus:before {
  content: "\e9e6";
}

.icon-mobile:before {
  content: "\e9e7";
}

.icon-modern-mic:before {
  content: "\e9e8";
}

.icon-moon:before {
  content: "\e9e9";
}

.icon-mouse-pointer:before {
  content: "\e9ea";
}

.icon-mouse:before {
  content: "\e9eb";
}

.icon-music:before {
  content: "\e9ec";
}

.icon-network:before {
  content: "\e9ed";
}

.icon-new-message:before {
  content: "\e9ee";
}

.icon-new:before {
  content: "\e9ef";
}

.icon-news:before {
  content: "\e9f0";
}

.icon-newsletter:before {
  content: "\e9f1";
}

.icon-note:before {
  content: "\e9f2";
}

.icon-notification:before {
  content: "\e9f3";
}

.icon-notifications-off:before {
  content: "\e9f4";
}

.icon-old-mobile:before {
  content: "\e9f5";
}

.icon-old-phone:before {
  content: "\e9f6";
}

.icon-open-book:before {
  content: "\e9f7";
}

.icon-palette:before {
  content: "\e9f8";
}

.icon-paper-plane:before {
  content: "\e9f9";
}

.icon-pencil:before {
  content: "\e9fa";
}

.icon-phone:before {
  content: "\e9fb";
}

.icon-pie-chart:before {
  content: "\e9fc";
}

.icon-pin:before {
  content: "\e9fd";
}

.icon-plus:before {
  content: "\e9fe";
}

.icon-popup:before {
  content: "\e9ff";
}

.icon-power-plug:before {
  content: "\ea00";
}

.icon-price-ribbon:before {
  content: "\ea01";
}

.icon-price-tag:before {
  content: "\ea02";
}

.icon-print:before {
  content: "\ea03";
}

.icon-progress-empty:before {
  content: "\ea04";
}

.icon-progress-full:before {
  content: "\ea05";
}

.icon-progress-one:before {
  content: "\ea06";
}

.icon-progress-two:before {
  content: "\ea07";
}

.icon-publish:before {
  content: "\ea08";
}

.icon-quote:before {
  content: "\ea09";
}

.icon-radio:before {
  content: "\ea0a";
}

.icon-remove-user:before {
  content: "\ea0b";
}

.icon-reply-all:before {
  content: "\ea0c";
}

.icon-reply:before {
  content: "\ea0d";
}

.icon-resize-100:before {
  content: "\ea0e";
}

.icon-resize-full-screen:before {
  content: "\ea0f";
}

.icon-retweet:before {
  content: "\ea10";
}

.icon-rocket:before {
  content: "\ea11";
}

.icon-round-brush:before {
  content: "\ea12";
}

.icon-rss:before {
  content: "\ea13";
}

.icon-ruler:before {
  content: "\ea14";
}

.icon-save:before {
  content: "\ea15";
}

.icon-scissors:before {
  content: "\ea16";
}

.icon-select-arrows:before {
  content: "\ea17";
}

.icon-share-alternative:before {
  content: "\ea18";
}

.icon-share:before {
  content: "\ea19";
}

.icon-shareable:before {
  content: "\ea1a";
}

.icon-shield:before {
  content: "\ea1b";
}

.icon-shop:before {
  content: "\ea1c";
}

.icon-shopping-bag:before {
  content: "\ea1d";
}

.icon-shopping-basket:before {
  content: "\ea1e";
}

.icon-shopping-cart:before {
  content: "\ea1f";
}

.icon-shuffle:before {
  content: "\ea20";
}

.icon-signal:before {
  content: "\ea21";
}

.icon-sound-mix:before {
  content: "\ea22";
}

.icon-sound-mute:before {
  content: "\ea23";
}

.icon-sound:before {
  content: "\ea24";
}

.icon-sports-club:before {
  content: "\ea25";
}

.icon-spreadsheet:before {
  content: "\ea26";
}

.icon-squared-cross:before {
  content: "\ea27";
}

.icon-squared-minus:before {
  content: "\ea28";
}

.icon-squared-plus:before {
  content: "\ea29";
}

.icon-star-outlined:before {
  content: "\ea2a";
}

.icon-star:before {
  content: "\ea2b";
}

.icon-stopwatch:before {
  content: "\ea2c";
}

.icon-suitcase:before {
  content: "\ea2d";
}

.icon-swap:before {
  content: "\ea2e";
}

.icon-sweden:before {
  content: "\ea2f";
}

.icon-switch:before {
  content: "\ea30";
}

.icon-tablet-mobile-combo:before {
  content: "\ea31";
}

.icon-tablet:before {
  content: "\ea32";
}

.icon-tag:before {
  content: "\ea33";
}

.icon-text-document-inverted:before {
  content: "\ea34";
}

.icon-text-document:before {
  content: "\ea35";
}

.icon-text:before {
  content: "\ea36";
}

.icon-thermometer:before {
  content: "\ea37";
}

.icon-thumbs-down:before {
  content: "\ea38";
}

.icon-thumbs-up:before {
  content: "\ea39";
}

.icon-thunder-cloud:before {
  content: "\ea3a";
}

.icon-ticket:before {
  content: "\ea3b";
}

.icon-time-slot:before {
  content: "\ea3c";
}

.icon-tools:before {
  content: "\ea3d";
}

.icon-traffic-cone:before {
  content: "\ea3e";
}

.icon-trash:before {
  content: "\ea3f";
}

.icon-tree:before {
  content: "\ea40";
}

.icon-triangle-down:before {
  content: "\ea41";
}

.icon-triangle-left:before {
  content: "\ea42";
}

.icon-triangle-right:before {
  content: "\ea43";
}

.icon-triangle-up:before {
  content: "\ea44";
}

.icon-trophy:before {
  content: "\ea45";
}

.icon-tv:before {
  content: "\ea46";
}

.icon-typing:before {
  content: "\ea47";
}

.icon-uninstall:before {
  content: "\ea48";
}

.icon-unread:before {
  content: "\ea49";
}

.icon-untag:before {
  content: "\ea4a";
}

.icon-upload-to-cloud:before {
  content: "\ea4b";
}

.icon-upload:before {
  content: "\ea4c";
}

.icon-user:before {
  content: "\ea4d";
}

.icon-users:before {
  content: "\ea4e";
}

.icon-v-card:before {
  content: "\ea4f";
}

.icon-video-camera:before {
  content: "\ea50";
}

.icon-video:before {
  content: "\ea51";
}

.icon-vinyl:before {
  content: "\ea52";
}

.icon-voicemail:before {
  content: "\ea53";
}

.icon-wallet:before {
  content: "\ea54";
}

.icon-warning:before {
  content: "\ea55";
}

.icon-water:before {
  content: "\ea56";
}

.icon-px-with-circle:before {
  content: "\ea57";
}

.icon-px:before {
  content: "\ea58";
}

.icon-app-store:before {
  content: "\ea59";
}

.icon-baidu:before {
  content: "\ea5a";
}

.icon-basecamp:before {
  content: "\ea5b";
}

.icon-behance:before {
  content: "\ea5c";
}

.icon-creative-cloud:before {
  content: "\ea5d";
}

.icon-dribbble-with-circle:before {
  content: "\ea5e";
}

.icon-dribbble:before {
  content: "\ea5f";
}

.icon-dropbox:before {
  content: "\ea60";
}

.icon-evernote:before {
  content: "\ea61";
}

.icon-facebook-with-circle:before {
  content: "\ea62";
}

.icon-facebook:before {
  content: "\ea63";
}

.icon-flattr:before {
  content: "\ea64";
}

.icon-flickr-with-circle:before {
  content: "\ea65";
}

.icon-flickr:before {
  content: "\ea66";
}

.icon-foursquare:before {
  content: "\ea67";
}

.icon-github-with-circle:before {
  content: "\ea68";
}

.icon-github:before {
  content: "\ea69";
}

.icon-google-drive:before {
  content: "\ea6a";
}

.icon-google-hangouts:before {
  content: "\ea6b";
}

.icon-google-play:before {
  content: "\ea6c";
}

.icon-google-with-circle:before {
  content: "\ea6d";
}

.icon-google:before {
  content: "\ea6e";
}

.icon-grooveshark:before {
  content: "\ea6f";
}

.icon-houzz:before {
  content: "\ea70";
}

.icon-icloud:before {
  content: "\ea71";
}

.icon-instagram-with-circle:before {
  content: "\ea72";
}

.icon-instagram:before {
  content: "\ea73";
}

.icon-lastfm-with-circle:before {
  content: "\ea74";
}

.icon-lastfm:before {
  content: "\ea75";
}

.icon-linkedin-with-circle:before {
  content: "\ea76";
}

.icon-linkedin:before {
  content: "\ea77";
}

.icon-mail-with-circle:before {
  content: "\ea78";
}

.icon-medium-with-circle:before {
  content: "\ea79";
}

.icon-medium:before {
  content: "\ea7a";
}

.icon-mixi:before {
  content: "\ea7b";
}

.icon-onedrive:before {
  content: "\ea7c";
}

.icon-paypal:before {
  content: "\ea7d";
}

.icon-picasa:before {
  content: "\ea7e";
}

.icon-pinterest-with-circle:before {
  content: "\ea7f";
}

.icon-pinterest:before {
  content: "\ea80";
}

.icon-qq-with-circle:before {
  content: "\ea81";
}

.icon-qq:before {
  content: "\ea82";
}

.icon-raft-with-circle:before {
  content: "\ea83";
}

.icon-raft:before {
  content: "\ea84";
}

.icon-rainbow:before {
  content: "\ea85";
}

.icon-rdio-with-circle:before {
  content: "\ea86";
}

.icon-rdio:before {
  content: "\ea87";
}

.icon-renren:before {
  content: "\ea88";
}

.icon-scribd:before {
  content: "\ea89";
}

.icon-sina-weibo:before {
  content: "\ea8a";
}

.icon-skype-with-circle:before {
  content: "\ea8b";
}

.icon-skype:before {
  content: "\ea8c";
}

.icon-slideshare:before {
  content: "\ea8d";
}

.icon-smashing:before {
  content: "\ea8e";
}

.icon-soundcloud:before {
  content: "\ea8f";
}

.icon-spotify-with-circle:before {
  content: "\ea90";
}

.icon-spotify:before {
  content: "\ea91";
}

.icon-stumbleupon-with-circle:before {
  content: "\ea92";
}

.icon-stumbleupon:before {
  content: "\ea93";
}

.icon-swarm:before {
  content: "\ea94";
}

.icon-tripadvisor:before {
  content: "\ea95";
}

.icon-tumblr-with-circle:before {
  content: "\ea96";
}

.icon-tumblr:before {
  content: "\ea97";
}

.icon-twitter-with-circle:before {
  content: "\ea98";
}

.icon-twitter:before {
  content: "\ea99";
}

.icon-vimeo-with-circle:before {
  content: "\ea9a";
}

.icon-vimeo:before {
  content: "\ea9b";
}

.icon-vine-with-circle:before {
  content: "\ea9c";
}

.icon-vine:before {
  content: "\ea9d";
}

.icon-vk-alternitive:before {
  content: "\ea9e";
}

.icon-vk-with-circle:before {
  content: "\ea9f";
}

.icon-vk:before {
  content: "\eaa0";
}

.icon-windows-store:before {
  content: "\eaa1";
}

.icon-xing-with-circle:before {
  content: "\eaa2";
}

.icon-xing:before {
  content: "\eaa3";
}

.icon-yelp:before {
  content: "\eaa4";
}

.icon-youko-with-circle:before {
  content: "\eaa5";
}

.icon-youko:before {
  content: "\eaa6";
}

.icon-youtube-with-circle:before {
  content: "\eaa7";
}

.icon-youtube:before {
  content: "\eaa8";
}

/******/
[class^=logo-],
[class*=" logo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Desigual-Logos" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}

.logo-desigual:before {
  content: "\e916";
}

body {
  -webkit-font-smoothing: antialiased;
}

h1, h2, h4, h5, h6,
.h1, .h2, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "HelveticaNeueRoman";
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  color: #042d36;
}

h1,
.h1 {
  margin-bottom: 0.5rem;
  font-family: "HelveticaNeueRoman";
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  color: #042d36;
  font-size: 3em;
}

h2,
.h2 {
  margin-bottom: 0.5rem;
  font-family: "HelveticaNeueRoman";
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  color: #042d36;
  font-size: 2.25em;
}

h3,
.h3 {
  margin-bottom: 0.5rem;
  font-family: "HelveticaNeueRoman";
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  color: #042d36;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

b,
strong {
  font-weight: normal;
  font-family: "HelveticaNeueMedium";
}

.font-light {
  font-family: "HelveticaNeueRoman";
}

.font-bold {
  font-family: "HelveticaNeueMedium";
}

.font-custom {
  font-family: "Custom";
  line-height: 0.9;
  font-weight: 400;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.dgl-required {
  color: #D72931;
  font-size: 1em;
}

.form-control {
  font-size: 1rem;
}
.form-control::placeholder {
  color: #A8A8A8;
}
.form-control.disabled, .form-control:disabled {
  background-color: #E6E6E6;
  border-color: #E6E6E6;
  color: #A8A8A8;
  cursor: default;
  opacity: 0.65;
}

.form-control-sm {
  font-size: 1rem;
}

.form-control-lg {
  font-size: 1.5rem;
}

.form-check-inline + .form-check-inline {
  margin-left: 2.5rem;
}

.label,
.label-sm,
.label-lg {
  color: #4D4D4D;
}

.label-sm {
  font-size: 0.875rem;
}

.label {
  font-size: 1rem;
}

.label-lg {
  font-size: 1.25rem;
}

.form-sub {
  padding-left: 3em;
}

.was-validated .form-control:valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-valid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-valid:focus,
.custom-select.is-invalid:focus {
  box-shadow: none !important;
}

label {
  margin-bottom: 0.2rem;
}

.input-select {
  position: relative;
  z-index: 1;
}
.input-select select.form-control {
  appearance: none;
  text-align: left;
  box-shadow: none !important;
  border-color: #000;
  padding-right: 3.3rem;
  background-color: transparent;
  cursor: pointer;
}
.input-select select.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #757575;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.input-select select.form-control:focus {
  background-color: transparent;
  border-color: #000;
}
.input-select select.form-control:focus + :after {
  transform: rotate(180deg);
}
.input-select select.form-control.disabled, .input-select select.form-control:disabled {
  background-color: #E6E6E6;
  border-color: #E6E6E6;
  color: #A8A8A8;
  cursor: default;
}
.input-select select.form-control.disabled:after, .input-select select.form-control:disabled:after {
  display: flex;
  align-items: center;
  font-family: "Icomoon";
  content: "\e949";
  font-size: 1.25rem;
  width: 1em;
  height: 100%;
  line-height: 1;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  transform-origin: 50% 50% 0;
  transition: transform 0.15s;
  top: 0.7em;
  font-size: 1rem;
  z-index: -1;
}
.input-select select option {
  font-size: 1rem;
}
.input-select.disabled:after {
  z-index: 1;
  color: #A8A8A8;
}
.input-select:after {
  display: flex;
  align-items: center;
  font-family: "Icomoon";
  content: "\e949";
  font-size: 1.25rem;
  width: 1em;
  height: 100%;
  line-height: 1;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  transform-origin: 50% 50% 0;
  transition: transform 0.15s;
  color: inherit;
  z-index: -1;
}
.input-select:focus {
  outline: none;
  color: #4D4D4D;
}
.input-select:focus select.form-control {
  color: inherit;
  border-color: currentColor;
}
.input-select:focus:after {
  transform: rotate(180deg);
}
.input-select:hover {
  color: #4D4D4D;
}
.input-select:hover select.form-control {
  color: inherit;
  border-color: currentColor;
}
.input-select.input-select-sm select.form-control {
  padding: 0.44rem 1.2rem;
  padding-right: 3.2rem;
  line-height: 1.5;
  height: auto;
  font-size: 1rem;
}
.input-select.input-select-sm:after {
  right: 0.7rem;
}
.input-select.input-select-lg select.form-control {
  padding: 0.9rem 1.5rem;
  padding-right: 3.5rem;
  line-height: 1.5;
  height: auto;
  font-size: 1.5rem;
}
.input-select.input-select-lg:after {
  right: 1rem;
}

.inputview {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
  font-size: 1.1rem;
  display: block;
  width: 100%;
  padding: 0 1rem 1.4rem 0;
  line-height: 1.25;
  min-height: 2.65rem;
}

.form-single {
  margin: 0 auto;
  max-width: 36rem;
}
.form-single .single-input {
  display: block;
  max-width: 26rem;
}
.form-single .single-input-md {
  display: block;
  max-width: 16rem;
}
.form-single .single-input-sm {
  display: block;
  max-width: 11rem;
}
.form-single .single-input-xs {
  display: block;
  max-width: 8rem;
}
.form-single .single-dropdown {
  display: block;
  max-width: 20rem;
}
.form-single .single-dropdown-xs {
  display: inline-block;
  max-width: 8rem;
}

.form-single-sm {
  max-width: 41rem;
}

.form-sub-title {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
  margin: 3.2rem 0 0.8rem 0;
  color: #4D4D4D;
}

.notifications {
  position: relative;
  display: block;
  font-size: 1rem;
}
.notifications .header-title {
  background-color: #C2C2C2;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}

.list {
  margin: 0;
  padding: 0;
}
.list li {
  position: relative;
  display: block;
  border-bottom: 1px solid #C2C2C2;
  padding: 1rem;
  padding-right: 3rem;
  cursor: pointer;
}
.list li:not(.title):after {
  content: "\e952";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0.75rem;
  font-size: 1.5em;
  top: 50%;
  transform: translateY(-50%);
}
.list li.title {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
}
.list li .item {
  line-height: 125%;
}
.list li:hover .item, .list li:hover:after {
  color: #4D4D4D;
}
.list.list-notifications li .item:first-child {
  margin-bottom: 0.5em;
}
.list.list-reports li {
  display: flex;
  flex-flow: row wrap;
}
.list.list-reports li .item:first-child {
  flex: 1 1 0%;
  padding-right: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.list.list-reports li .item:last-child {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
}

.badge {
  font-size: 0.625rem;
}

.badge-pill {
  padding: 0.6em 1.3em;
  border-radius: 2em;
}

.badge-info {
  background-color: #6443B5;
}

.badge-danger {
  background-color: #D72931;
}

body {
  overflow: hidden;
}

button {
  background: transparent;
  border: 0;
}

:root {
  --main-layout-size: calc(100vh - 69px + 51px + 40px);
  --main-layout-top: calc(51px + 40px);
  --main-layout-padding: 0 1rem 1rem;
  --main-layout-position: relative;
}
@media (min-width: 992px) {
  :root {
    --main-layout-size: calc( 100vh - 69px);
    --main-layout-top: 10px;
    --main-layout-padding: 1rem;
    --main-layout-position: absolute;
  }
}

.main-layout-content {
  flex: 1 1 0%;
  background-color: white;
  margin: 0;
  height: var(--main-layout-size);
  top: 0;
  padding-top: var(--main-layout-top);
  overflow-y: auto;
  position: relative;
}
.main-layout-content .grid-layout-content {
  position: var(--main-layout-position);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

.main-layout-content::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c4d2dd;
}

.item-product {
  display: inline-block;
  width: 100%;
  padding: 6%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.34, 1.13, 0.39, 0.99);
}
.item-product:hover {
  transform: scale(1.1);
}
.product-colors .item-product {
  position: relative;
}
.product-colors .item-product:hover {
  transform: scale(1);
}

.item-product-check {
  position: absolute;
  top: 8%;
  right: 2%;
  border-radius: 50%;
  background-color: #8bbe43;
  color: white;
  overflow: hidden;
  vertical-align: middle;
  width: 20%;
  height: auto;
}

.item-product-color {
  position: relative;
  padding: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.item-product-color li {
  cursor: default;
  width: 10%;
  min-width: 4px;
  min-height: 4px;
  max-width: 8px;
  max-height: 8px;
  border-radius: 0;
  display: inline-block;
  margin-right: 6%;
  display: var(--item-display-color);
}
.item-product-color li:last-child {
  margin-right: 0;
}
.item-product-color li:before {
  content: "";
  display: block;
  width: 100%;
  z-index: -999999;
  padding-top: 100%;
}
.item-product-color li[style*="background-color: rgb(255, 255, 255)"] {
  box-shadow: inset 0 0 0 1px #d7e2e6;
}

.item-product-img {
  display: block;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  margin-top: 3%;
}
.item-product-img:before {
  content: "";
  display: block;
  width: 100%;
  z-index: -999999;
  padding-top: 150%;
}

/*
Custom components and 3rd party components overrides.
*/
.swiper-pagination-handle [class*=swiper-pagination-bullet] {
  background-color: #000;
  opacity: 0.2;
}
.swiper-pagination-handle .swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
  background-image: none !important;
  font-size: 1.7em;
  width: 1em;
  height: 1em;
  cursor: pointer;
}
.swiper-button-prev:before,
.swiper-button-next:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon" !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: inherit;
  color: #A8A8A8;
}
.swiper-button-prev {
  margin-left: -0.4em;
}
.swiper-button-prev:before {
  content: "\e951";
}

.swiper-button-next {
  margin-right: -0.4em;
}
.swiper-button-next:before {
  content: "\e952";
}

[class*=dglSpinner]:before {
  height: 40px;
  width: 40px;
  top: calc(50% - (40px/2));
  left: calc(50% - (40px/2));
  border-radius: 100%;
  border-top: 2px solid rgba(14, 17, 69, 0.3);
  border-left: 2px solid rgba(14, 17, 69, 0.3);
  border-bottom: 2px solid #0e1145;
  border-right: 2px solid rgba(14, 17, 69, 0.3);
  animation: rotation 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform-origin: center center;
  z-index: 1039;
}