.p-dialog {
  width: 98%;
  max-width: 1420px;

  .p-dialog-header {
    padding: 0.4rem!important;
    border-bottom: 0!important;
  }
}

.p-dialog {
  &.p-widget-content,
  .p-widget-content {
    background: white;
  }

  &.p-widget-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.p-shadow {
    box-shadow: none;
  }

  .p-widget-header {
    background-color: transparent;
    text-transform: inherit;
  }

  .p-dialog-title {
    font-size: 1.75rem;
  }

  .p-dialog-titlebar,
  .p-dialog-content,
  .p-dialog-footer {
    padding: 1rem !important;
  }

  .p-dialog-content {
    font-size: 1.125rem;
  }

  .p-dialog-titlebar-close {
    font-size: 1.5rem;
    border: none;
    position: absolute;
    float: none;
    top: 1rem;
    right: 0;
    z-index: 1;
  }

  .p-dialog-titlebar-icon:hover {
    border-color: transparent;
    background: transparent;
    color: $dgl-grey-strong;
  }

  .p-widget-content {
    border: none;
  }

  .p-button {
    & + .p-button {
      margin-left: .5em;
    }
  }
}


@include media-breakpoint-down(sm) {
  .p-dialog {
    width: 100% !important;
    height: 90vh;
  }
}
