@mixin spinner-global ($size, $border, $color) {

    height: $size;
    width: $size;

    top: calc(50% - (#{$size}/2));
    left: calc(50% - (#{$size}/2));

    border-radius: 100%;

    border-top: $border solid rgba($color,.3);
    border-left: $border solid rgba($color,.3);
    border-bottom: $border solid $color;
  border-right: $border solid rgba($color, .3);

    animation: rotation 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55); //rotation 1s infinite cubic-bezier(0.4, 0, 0.2, 1);

  transform-origin: center center;
  z-index: #{$zindex-modal-backdrop - 1};
}


@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}