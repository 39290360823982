//
/* Spinner - Loading*/
//


$_btn_spinner_size: 1.15em;
$_btn_spinner_time: 1s;

@mixin spinner-global ($size, $border, $color) {

    height: $size;
    width: $size;

    top: calc(50% - (#{$size}/2));
    left: calc(50% - (#{$size}/2));

    border-radius: 100%;

    border-top: $border solid rgba($color,.3);
    border-left: $border solid rgba($color,.3);
    border-bottom: $border solid $color;
  border-right: $border solid rgba($color, .3);

    animation: rotation 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55); //rotation 1s infinite cubic-bezier(0.4, 0, 0.2, 1);

  transform-origin: center center;
  z-index: #{$zindex-modal-backdrop - 1};
}


@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}

[class*="dglSpinner"] {

    &:before {
        content: '';
        display: block;
        position: absolute;

      @include spinner-global (40px, 2px, $dgl-black);
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .9);
        transition: background-color 0.5s ease;
        top: 0;
        left: 0;
        z-index: 21;
    }

  &.spinner-layout {

    &:before,
         &:after {
            position: fixed;
         }
    }

    &[class*="-top"] {
        &:before {
            top: 2rem;
        }
    }

    // Spinner in a button
    &.btn {
        background-color: $dgl-primary;
        border-color: $dgl-primary;
        color: $dgl-primary;
        cursor: default;

        &:before {
          @include spinner-global (1em, 2px, $dgl-white);
        }

        &:after {
            display: none;
        }


        &[class*="-left"] {
          color: $dgl-white;

            &:before {
                position: relative;
                display: inline-block;
                left: -($_btn_spinner_size/2.5);
                top: $_btn_spinner_size * .15;
                margin-left: $_btn_spinner_size/2.5;
            }
        }

    }
}
