body {
    overflow: hidden;
}

button {
    background: transparent;
    border: 0;
}

:root {
    --main-layout-size: calc(100vh - #{$dgl-bboa-header-height} + #{$dgl-bboa-access-menu-height} + #{$dgl-bboa-breadcrumb-height});
    --main-layout-top:  calc(#{$dgl-bboa-access-menu-height} + #{$dgl-bboa-breadcrumb-height});
    --main-layout-padding: 0 1rem 1rem;
    --main-layout-position: relative;

    @include media-breakpoint-up(lg) {
        --main-layout-size: calc( 100vh - #{$dgl-bboa-header-height}); 
        --main-layout-top : 10px;
        --main-layout-padding: 1rem;
        --main-layout-position: absolute;
    }
}


.main-layout-content {
    flex: 1 1 0%;
    background-color: white;
    margin: 0;
    height: var(--main-layout-size);
    top: 0;
    padding-top: var(--main-layout-top);
    overflow-y: auto;
    position: relative;

    .grid-layout-content {
        position: var(--main-layout-position);
        top: 0; 
        right: 0; 
        left: 0; 
        bottom: 0;
    }
}


::-webkit-scrollbar {
    width: 4px;
}

.main-layout-content::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #c4d2dd; //TODO variable
}