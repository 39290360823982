.fa-check{
	@include icon-style;

	&:before{
		content: $icon-check;
	}
}

.fa-close{
	@include icon-style;

	&:before{
		content: $icon-cross;
	}
}

.fa-info-circle{
	@include icon-style;

	&:before{
		content: $icon-info-dgl;
	}
}

.fa-warning,
.fa-exclamation-circle{
	@include icon-style;

	&:before{
		content: $icon-sign-warning-dgl;
	}
}

.fa-sign-out{
    @include icon-style;

    &:before{
        content: $icon-log-out;
    }
}

.fa-calendar{
	@include icon-style;

	&:before{
		content: $icon-calendar;
	}
}

.fa-search{
	@include icon-style;

	&:before{
		content: $icon-magnifying-glass;
	}
}

.fa-print{
	@include icon-style;

	&:before{
		content: $icon-print;
	}
}

.fa-user,
.fa-user-o{
	@include icon-style;

	&:before{
		content: $icon-user;
	}
}

.fa-navicon{
	@include icon-style;

	&:before{
		content: $icon-menu;
	}
}

.fa-refresh{
	@include icon-style;

	&:before{
		content: $icon-cycle;
	}
}

.fa-trash{
	@include icon-style;

	&:before{
		content: $icon-trash;
	}
}

.fa-edit{
	@include icon-style;

	&:before{
		content: $icon-edit;
	}
}

.fa-plus{
	@include icon-style;

	&:before{
		content: $icon-plus;
	}
}

.fa-question-circle{
	@include icon-style;

	&:before{
		content: $icon-help-with-circle;
	}
}