.badge{
	font-size: .625rem;
}

.badge-pill{
    padding: .6em 1.3em;
	border-radius: 2em;
}

.badge-info{
	background-color: $dgl-purple;
}

.badge-danger{
	background-color: $dgl-discount;
}