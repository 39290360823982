@keyframes ui-messages-enter {
	from   {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.ui-messages{
	margin-top: 0;
	padding: 1rem $input-btn-padding-x;
	position: relative;
	animation: ui-messages-enter $transition-enter-time;

	.ui-messages-close{
		top: 10px;
		right: 10px;
		color: inherit;
	}

	.ui-messages-icon{
		border: 1px solid currentColor;
		border-radius: 50%;
	    width: auto;
        margin-right: .7em;
		padding: .3em;
		font-size: 1.5em;

		&:before{
			width: 1em;
			display: inline-block;
			text-align: center;
		}
	}

	.icon-circle-dgl{
		align-self: flex-start;
		font-size: inherit;
	    margin-top: -.3rem;
	}

	ul{}

	.ui-messages-summary,
	.ui-messages-detail{
		display: block;
	}

	.ui-messages-summary{
		@include bold-font;
	}

	.ui-messages-detail{}
}

.ui-messages-success{
	color: $dgl-success-text;
	background-color: $dgl-success-status;
	border-color: $dgl-success-status;
}

.ui-messages-info{
	color: $dgl-info-text;
	background-color: $dgl-info-status;
	border-color: $dgl-info-status;
}

.ui-messages-warn{
	color: $dgl-warning-text;
	background-color: $dgl-warning-status;
	border-color: $dgl-warning-status;
}

.ui-messages-error{
	color: $dgl-danger-text;
	background-color: $dgl-danger-status;
	border-color: $dgl-danger-status;
}

p-message{

	.ui-messages-success,
	.ui-messages-info,
	.ui-messages-warn,
	.ui-messages-error{
		background-color: transparent;
		border-color: transparent;
	}

	.ui-messages-error{
		color: $dgl-red;
	}
}