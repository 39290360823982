


[class*="dglSpinner"] {

    &:before {
      @include spinner-global (40px, 2px, $dgl-color-spinner);
    }

}
