.p-button:focus {
  box-shadow: none;
}

.p-button.p-togglebutton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $shade000 !important;
  border: 0;
  background: transparent !important;

  &.p-state-active {
    background-color: $dgl-bboa-menu-tertiary-color;

    .p-button-text {
      &:after {
        color: $dgl-bboa-color-grey-6;
        top: 9px;
        left: -1px;
        font-size: 20px;
      }
    }
  }
}

.p-togglebutton.p-button:not(.p-disabled).p-highlight,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background-color: $dgl-bboa-menu-tertiary-color!important;
}

.p-togglebutton.p-button:not(.p-disabled).p-highlight:focus {

}

.ui-button.button-kpi,
.ui-button.orders-group {
  padding: 0.7rem 1.5rem;
  color: $shade000;
}

.btn-refresh.rounded-circle {
  .icon-cycle {
    margin-top: 10px;
  }
}
