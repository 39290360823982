.dropdown{
	display: inline-block;
	z-index: auto !important;

	&.show{
		z-index: 2 !important;
	}

	[class*="btn-outline"] {
		//color: $gray-700;
		
		&:after{
			color: inherit;
		}
	}
}

//Selected item
.ng-dirty {

	.dropdown-toggle {
    	background-color: $dgl-gray-100;

    	[class*="btn-outline"] {
    		&:hover {
    			color: $dgl-black !important;
    		}
    	}
    }
}


.is-submitted{
	
	//invalid	
	&.ng-invalid{

		.dropdown-toggle:not(:hover,:focus) {
			border-color: $dgl-red;

			&:after{
				color: $dgl-red;
			}
		}
	}
}

// Selector
.dropdown-toggle {
	position: relative;
	width: 100%;
	@include custom-select-content;

	&:after{
		@include custom-select-arrow;
	}

	&.btn-sm {
	    @include custom-select-content-sm;

		&:after {
			@include custom-select-arrow-sm;
		}
	}

	&.btn-lg {
		@include custom-select-content-lg;

		&:after {
			@include custom-select-arrow-lg;
		}
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}
	
	.show & {
		&:after {
			@include custom-select-arrow-active;
		}
	}
}

// Dropdown list
.dropdown-menu{

	&.show{
		font-size: $input-font-size-base;
		min-width: 0;
		
		.dropdown-item{}			
	}
}

// List item
.dropdown-item{
	cursor: pointer;
	padding: #{$dropdown-item-padding-x / 2} $dropdown-item-padding-x;
	background-color: $dgl-white;
	transition: none;

	&:first-of-type{
		border-top: 1px solid $dropdown-border-color;
	}

	&:focus{
		outline: none;
	}

	&:hover {
		background-color: $dgl-primary;
		color: $dgl-white;
	}
}

@include media-breakpoint-down(sm) {

	.dropdown{

		&:after{
			opacity: 0;
			@include transition($modal-transition);
		}

		&.show{

			&:after{
				content: '';
				display: block;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: $zindex-modal-backdrop;
				background-color: $modal-backdrop-bg;
				opacity: $modal-backdrop-opacity;
			}
		}
	}

	// Dropdown list
	.dropdown-menu{

		&.show{
			position: fixed !important;
			top: 50% !important;
			left: 2em !important;
			z-index: #{$zindex-modal + 1};
			right: 2em !important;
			transform: translateY(-50%) !important;
			max-width: 100vw;
			max-height: 100vh;
			overflow: hidden;
			overflow-y: auto;
			padding: 0;
			margin: 0;
			border: 1px solid $dropdown-border-color;
			@include box-shadow($modal-content-box-shadow-xs);
		}
	}
}

@include media-breakpoint-up(md) {

	// Selector
	.dropdown-toggle{

		.show &{
			z-index: 1;
			background-color: transparent !important;
			color: $gray-700; //$black !important;
			border-color: transparent !important;

			&:before{
				color: $dgl-btn-black-hover-color;
			}
		}
	}

	// Dropdown list
	.dropdown-menu{
		transform: none !important;

		&.show{
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			padding: $input-height 1px 1px 1px;
			margin: 0 0 1em 0;
			z-index: 0;
			border: 1px solid $dgl-btn-black-hover-color;
			box-shadow: 0px 0px 0px 0px;
		}

		.btn-sm + &.show{
			padding: $input-height-sm 0 0 0;

			.dropdown-item{
				padding: 0.25rem .8rem;
			}
		}

		.btn-lg + &.show{
			//padding: $input-height-lg 0 0 0;
			padding: 3.3em 0 0 0;
		}
	}

	.dropdown-item{
		font-size: .8em;
	}
}