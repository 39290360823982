.notifications {
    position: relative;
    display: block;
    font-size: 1rem;

    .header-title {
        background-color: $dgl-grey-dark;
        padding: .5rem 1rem;
        font-size: 1.1rem;
    }
}

.list {
    margin: 0;
    padding: 0;

    li {
        position: relative;
        display: block;
        border-bottom: 1px solid $dgl-grey-dark;
        padding: 1rem;
        padding-right: 3rem;
        cursor: pointer;

        &:not(.title):after {
            content: $icon-chevron-thin-right;
            @include icon-style;
            position: absolute;
            right: .75rem;
            font-size: 1.5em;
            top: 50%;
            transform: translateY(-50%);
        }

        &.title {
            @include bold-font;
        }        

        .item {
            line-height: 125%;
        }

        &:hover {
            .item, &:after {
                color: $dgl-grey-strong;
            }
        }
    }

    &.list-notifications {
        li {
            .item:first-child {
                margin-bottom: .5em;
            }
        }
    }

    &.list-reports {
        li {
            display: flex;
            flex-flow: row wrap;

            .item {

                &:first-child {
                    flex: 1 1 0%;
                    padding-right: 1rem;
                   
                    @include text-ellipsis;
                }

                &:last-child {
                    @include bold-font;
                }
            }
        }
    }

}