//$fontFamily: Lucida Grande, Lucida Sans, Arial, sans-serif;
$fontFamily: $font-family-light							!default;
//$fontSize: 1em;
$fontSize: $input-font-size-base						!default;
$borderRadius: $btn-border-radius						!default;
$disabledOpacity: 0.35;

//Header
$headerBorderWidth: $input-border-width					!default;
$headerBorderColor: $dgl-grey-dark						!default;
$headerBgColor: $dgl-grey-light							!default;
$headerTextColor: #222222;
//$headerFontWeight: bold;
$headerIconTextColor: #222222;

//Content
$contentBorderWidth: $input-border-width				!default;
$contentBorderColor: $dgl-grey-dark						!default;
$contentBgColor: $dgl-grey-lighter						!default;
$contentTextColor: #362b36;

//Default State
$stateDefaultBorderWidth: $input-border-width			!default;
$stateDefaultBorderColor: $input-border-color			!default;
//$stateDefaultBgColor: #d7ebf9;
$stateDefaultBgColor: $dgl-white						!default;
$stateDefaultTextColor: $input-color					!default;

//Active State
$stateActiveBorderColor: $dgl-btn-black-hover-color		!default;
$stateActiveBgColor: $dgl-btn-black-hover-color 		!default;
$stateActiveTextColor: $dgl-white						!default;

//Highlight State
$stateHighlightBorderColor: $dgl-primary				!default;
$stateHighlightBgColor: $dgl-primary					!default;
$stateHighlightTextColor: $dgl-white					!default;

//Focus State
$stateFocusBorderColor: $dgl-input-focus-border-color	!default;
$stateFocusBgColor: #e4f1fb;
$stateFocusTextColor: #0070a3;

//Error State
$stateErrorBorderColor: #cd0a0a;
$stateErrorBgColor: #cd0a0a;
$stateErrorTextColor: #ffffff;

//Hover State
$stateHoverBorderColor: $dgl-btn-black-hover-color		!default;
$stateHoverBgColor: $dgl-btn-black-hover-color 			!default;
$stateHoverTextColor: $dgl-white						!default;

//Forms
$inputBgColor: $input-bg								!default;
$inputTextColor: $input-color							!default;
$invalidInputBorderColor: $dgl-red						!default;
$inputGroupTextColor: $headerTextColor					!default;

$iconSize: 1.5em;
$listPadding: .5em 1em;

@import "ui-mixins";