$icon-product: "\ead2";
$icon-table: "\ead3";
$icon-magnifying-glass-plus: "\eab0";
$icon-magnifying-glass-minus: "\eab1";
$icon-sound-muted-bigger: "\eab2";
$icon-sound-muted-medium: "\eab3";
$icon-sound-bigger: "\eab4";
$icon-sound-medium: "\eab5";
$icon-sent: "\eab6";
$icon-returned: "\eab7";
$icon-help-with-circle-black: "\eab8";
$icon-preparing: "\eab9";
$icon-play-bigger: "\eaba";
$icon-play-medium: "\eabb";
$icon-location-pin-black: "\eabc";
$icon-emoji-surprised: "\eabd";
$icon-pick: "\eabe";
$icon-pause-bigger: "\eabf";
$icon-pause-medium: "\eac0";
$icon-partially-returned: "\eac1";
$icon-online: "\eac2";
$icon-magic: "\eac3";
$icon-magic-three: "\eac4";
$icon-magic-two: "\eac5";
$icon-magic-one: "\eac6";
$icon-magic-logo: "\eac7";
$icon-linear-line: "\eac8";
$icon-heart-break-right: "\eac9";
$icon-heart-break-left: "\eaca";
$icon-filter-sort: "\eacb";
$icon-check-small: "\eacc";
$icon-check-bubble: "\eacd";
$icon-check-bubble-black: "\eace";
$icon-canceled: "\eacf";
$icon-alert: "\ead0";
$icon-360: "\ead1";
$icon-user-mask-dgl: "\eaac";
$icon-user-dgl: "\eaad";
$icon-cloud-upload-dgl: "\eaae";
$icon-mask-batman-dgl: "\eaaf";
$icon-home-dgl: "\eaaa";
$icon-mail-dgl: "\eaab";
$icon-scan-dgl: "\e900";
$icon-logodesigual: "\eaa9";
$icon-bin-dgl: "\e901";
$icon-cart-dgl: "\e902";
$icon-close-dgl: "\e903";
$icon-up-dgl: "\e904";
$icon-down-dgl: "\e905";
$icon-back-dgl: "\e906";
$icon-forward-dgl: "\e907";
$icon-sign-minus-dgl: "\e908";
$icon-sign-plus-dgl: "\e909";
$icon-search-dgl: "\e90a";
$icon-sign-warning-dgl: "\e90b";
$icon-info-dgl: "\e90c";
$icon-check-dgl: "\e90d";
$icon-add-to-list: "\e90e";
$icon-add-user: "\e90f";
$icon-address: "\e910";
$icon-adjust: "\e911";
$icon-air: "\e912";
$icon-aircraft-landing: "\e913";
$icon-aircraft-take-off: "\e914";
$icon-aircraft: "\e915";
$icon-align-bottom: "\e916";
$icon-align-horizontal-middle: "\e917";
$icon-align-left: "\e918";
$icon-align-right: "\e919";
$icon-align-top: "\e91a";
$icon-align-vertical-middle: "\e91b";
$icon-archive: "\e91c";
$icon-area-graph: "\e91d";
$icon-arrow-bold-down: "\e91e";
$icon-arrow-bold-left: "\e91f";
$icon-arrow-bold-right: "\e920";
$icon-arrow-bold-up: "\e921";
$icon-arrow-down: "\e922";
$icon-arrow-left: "\e923";
$icon-arrow-long-down: "\e924";
$icon-arrow-long-left: "\e925";
$icon-arrow-long-right: "\e926";
$icon-arrow-long-up: "\e927";
$icon-arrow-right: "\e928";
$icon-arrow-up: "\e929";
$icon-arrow-with-circle-down: "\e92a";
$icon-arrow-with-circle-left: "\e92b";
$icon-arrow-with-circle-right: "\e92c";
$icon-arrow-with-circle-up: "\e92d";
$icon-attachment: "\e92e";
$icon-awareness-ribbon: "\e92f";
$icon-back-in-time: "\e930";
$icon-back: "\e931";
$icon-bar-graph: "\e932";
$icon-battery: "\e933";
$icon-beamed-note: "\e934";
$icon-bell: "\e935";
$icon-blackboard: "\e936";
$icon-block: "\e937";
$icon-book: "\e938";
$icon-bookmark: "\e939";
$icon-bookmarks: "\e93a";
$icon-bowl: "\e93b";
$icon-box: "\e93c";
$icon-briefcase: "\e93d";
$icon-browser: "\e93e";
$icon-brush: "\e93f";
$icon-bucket: "\e940";
$icon-bug: "\e941";
$icon-cake: "\e942";
$icon-calculator: "\e943";
$icon-calendar: "\e944";
$icon-camera: "\e945";
$icon-ccw: "\e946";
$icon-chat: "\e947";
$icon-check: "\e948";
$icon-chevron-down: "\e949";
$icon-chevron-left: "\e94a";
$icon-chevron-right: "\e94b";
$icon-chevron-small-down: "\e94c";
$icon-chevron-small-left: "\e94d";
$icon-chevron-small-right: "\e94e";
$icon-chevron-small-up: "\e94f";
$icon-chevron-thin-down: "\e950";
$icon-chevron-thin-left: "\e951";
$icon-chevron-thin-right: "\e952";
$icon-chevron-thin-up: "\e953";
$icon-chevron-up: "\e954";
$icon-chevron-with-circle-down: "\e955";
$icon-chevron-with-circle-left: "\e956";
$icon-chevron-with-circle-right: "\e957";
$icon-chevron-with-circle-up: "\e958";
$icon-circle-with-cross: "\e959";
$icon-circle-with-minus: "\e95a";
$icon-circle-with-plus: "\e95b";
$icon-circle: "\e95c";
$icon-circular-graph: "\e95d";
$icon-clapperboard: "\e95e";
$icon-classic-computer: "\e95f";
$icon-clipboard: "\e960";
$icon-clock: "\e961";
$icon-cloud: "\e962";
$icon-code: "\e963";
$icon-cog: "\e964";
$icon-colours: "\e965";
$icon-compass: "\e966";
$icon-controller-fast-backward: "\e967";
$icon-controller-fast-forward: "\e968";
$icon-controller-jump-to-start: "\e969";
$icon-controller-next: "\e96a";
$icon-controller-paus: "\e96b";
$icon-controller-play: "\e96c";
$icon-controller-record: "\e96d";
$icon-controller-stop: "\e96e";
$icon-controller-volume: "\e96f";
$icon-copy: "\e970";
$icon-creative-commons-attribution: "\e971";
$icon-creative-commons-noderivs: "\e972";
$icon-creative-commons-noncommercial-eu: "\e973";
$icon-creative-commons-noncommercial-us: "\e974";
$icon-creative-commons-public-domain: "\e975";
$icon-creative-commons-remix: "\e976";
$icon-creative-commons-share: "\e977";
$icon-creative-commons-sharealike: "\e978";
$icon-creative-commons: "\e979";
$icon-credit-card: "\e97a";
$icon-credit: "\e97b";
$icon-crop: "\e97c";
$icon-cross: "\e97d";
$icon-cup: "\e97e";
$icon-cw: "\e97f";
$icon-cycle: "\e980";
$icon-database: "\e981";
$icon-dial-pad: "\e982";
$icon-direction: "\e983";
$icon-document-landscape: "\e984";
$icon-document: "\e985";
$icon-documents: "\e986";
$icon-dot-single: "\e987";
$icon-dots-three-horizontal: "\e988";
$icon-dots-three-vertical: "\e989";
$icon-dots-two-horizontal: "\e98a";
$icon-dots-two-vertical: "\e98b";
$icon-download: "\e98c";
$icon-drink: "\e98d";
$icon-drive: "\e98e";
$icon-drop: "\e98f";
$icon-edit: "\e990";
$icon-email: "\e991";
$icon-emoji-flirt: "\e992";
$icon-emoji-happy: "\e993";
$icon-emoji-neutral: "\e994";
$icon-emoji-sad: "\e995";
$icon-erase: "\e996";
$icon-eraser: "\e997";
$icon-export: "\e998";
$icon-eye-with-line: "\e999";
$icon-eye: "\e99a";
$icon-feather: "\e99b";
$icon-fingerprint: "\e99c";
$icon-flag: "\e99d";
$icon-flash: "\e99e";
$icon-flashlight: "\e99f";
$icon-flat-brush: "\e9a0";
$icon-flow-branch: "\e9a1";
$icon-flow-cascade: "\e9a2";
$icon-flow-line: "\e9a3";
$icon-flow-parallel: "\e9a4";
$icon-flow-tree: "\e9a5";
$icon-flower: "\e9a6";
$icon-folder-images: "\e9a7";
$icon-folder-music: "\e9a8";
$icon-folder-video: "\e9a9";
$icon-folder: "\e9aa";
$icon-forward: "\e9ab";
$icon-funnel: "\e9ac";
$icon-game-controller: "\e9ad";
$icon-gauge: "\e9ae";
$icon-globe: "\e9af";
$icon-graduation-cap: "\e9b0";
$icon-grid: "\e9b1";
$icon-hair-cross: "\e9b2";
$icon-hand: "\e9b3";
$icon-heart-outlined: "\e9b4";
$icon-heart: "\e9b5";
$icon-help-with-circle: "\e9b6";
$icon-help: "\e9b7";
$icon-home: "\e9b8";
$icon-hour-glass: "\e9b9";
$icon-image-inverted: "\e9ba";
$icon-image: "\e9bb";
$icon-images: "\e9bc";
$icon-inbox: "\e9bd";
$icon-infinity: "\e9be";
$icon-info-with-circle: "\e9bf";
$icon-info: "\e9c0";
$icon-install: "\e9c1";
$icon-key: "\e9c2";
$icon-keyboard: "\e9c3";
$icon-lab-flask: "\e9c4";
$icon-landline: "\e9c5";
$icon-language: "\e9c6";
$icon-laptop: "\e9c7";
$icon-layers: "\e9c8";
$icon-leaf: "\e9c9";
$icon-level-down: "\e9ca";
$icon-level-up: "\e9cb";
$icon-lifebuoy: "\e9cc";
$icon-light-bulb: "\e9cd";
$icon-light-down: "\e9ce";
$icon-light-up: "\e9cf";
$icon-line-graph: "\e9d0";
$icon-link: "\e9d1";
$icon-list: "\e9d2";
$icon-location-pin: "\e9d3";
$icon-location: "\e9d4";
$icon-lock-open: "\e9d5";
$icon-lock: "\e9d6";
$icon-log-out: "\e9d7";
$icon-login: "\e9d8";
$icon-loop: "\e9d9";
$icon-magnet: "\e9da";
$icon-magnifying-glass: "\e9db";
$icon-mail: "\e9dc";
$icon-man: "\e9dd";
$icon-map: "\e9de";
$icon-mask: "\e9df";
$icon-medal: "\e9e0";
$icon-megaphone: "\e9e1";
$icon-menu: "\e9e2";
$icon-merge: "\e9e3";
$icon-message: "\e9e4";
$icon-mic: "\e9e5";
$icon-minus: "\e9e6";
$icon-mobile: "\e9e7";
$icon-modern-mic: "\e9e8";
$icon-moon: "\e9e9";
$icon-mouse-pointer: "\e9ea";
$icon-mouse: "\e9eb";
$icon-music: "\e9ec";
$icon-network: "\e9ed";
$icon-new-message: "\e9ee";
$icon-new: "\e9ef";
$icon-news: "\e9f0";
$icon-newsletter: "\e9f1";
$icon-note: "\e9f2";
$icon-notification: "\e9f3";
$icon-notifications-off: "\e9f4";
$icon-old-mobile: "\e9f5";
$icon-old-phone: "\e9f6";
$icon-open-book: "\e9f7";
$icon-palette: "\e9f8";
$icon-paper-plane: "\e9f9";
$icon-pencil: "\e9fa";
$icon-phone: "\e9fb";
$icon-pie-chart: "\e9fc";
$icon-pin: "\e9fd";
$icon-plus: "\e9fe";
$icon-popup: "\e9ff";
$icon-power-plug: "\ea00";
$icon-price-ribbon: "\ea01";
$icon-price-tag: "\ea02";
$icon-print: "\ea03";
$icon-progress-empty: "\ea04";
$icon-progress-full: "\ea05";
$icon-progress-one: "\ea06";
$icon-progress-two: "\ea07";
$icon-publish: "\ea08";
$icon-quote: "\ea09";
$icon-radio: "\ea0a";
$icon-remove-user: "\ea0b";
$icon-reply-all: "\ea0c";
$icon-reply: "\ea0d";
$icon-resize-100: "\ea0e";
$icon-resize-full-screen: "\ea0f";
$icon-retweet: "\ea10";
$icon-rocket: "\ea11";
$icon-round-brush: "\ea12";
$icon-rss: "\ea13";
$icon-ruler: "\ea14";
$icon-save: "\ea15";
$icon-scissors: "\ea16";
$icon-select-arrows: "\ea17";
$icon-share-alternative: "\ea18";
$icon-share: "\ea19";
$icon-shareable: "\ea1a";
$icon-shield: "\ea1b";
$icon-shop: "\ea1c";
$icon-shopping-bag: "\ea1d";
$icon-shopping-basket: "\ea1e";
$icon-shopping-cart: "\ea1f";
$icon-shuffle: "\ea20";
$icon-signal: "\ea21";
$icon-sound-mix: "\ea22";
$icon-sound-mute: "\ea23";
$icon-sound: "\ea24";
$icon-sports-club: "\ea25";
$icon-spreadsheet: "\ea26";
$icon-squared-cross: "\ea27";
$icon-squared-minus: "\ea28";
$icon-squared-plus: "\ea29";
$icon-star-outlined: "\ea2a";
$icon-star: "\ea2b";
$icon-stopwatch: "\ea2c";
$icon-suitcase: "\ea2d";
$icon-swap: "\ea2e";
$icon-sweden: "\ea2f";
$icon-switch: "\ea30";
$icon-tablet-mobile-combo: "\ea31";
$icon-tablet: "\ea32";
$icon-tag: "\ea33";
$icon-text-document-inverted: "\ea34";
$icon-text-document: "\ea35";
$icon-text: "\ea36";
$icon-thermometer: "\ea37";
$icon-thumbs-down: "\ea38";
$icon-thumbs-up: "\ea39";
$icon-thunder-cloud: "\ea3a";
$icon-ticket: "\ea3b";
$icon-time-slot: "\ea3c";
$icon-tools: "\ea3d";
$icon-traffic-cone: "\ea3e";
$icon-trash: "\ea3f";
$icon-tree: "\ea40";
$icon-triangle-down: "\ea41";
$icon-triangle-left: "\ea42";
$icon-triangle-right: "\ea43";
$icon-triangle-up: "\ea44";
$icon-trophy: "\ea45";
$icon-tv: "\ea46";
$icon-typing: "\ea47";
$icon-uninstall: "\ea48";
$icon-unread: "\ea49";
$icon-untag: "\ea4a";
$icon-upload-to-cloud: "\ea4b";
$icon-upload: "\ea4c";
$icon-user: "\ea4d";
$icon-users: "\ea4e";
$icon-v-card: "\ea4f";
$icon-video-camera: "\ea50";
$icon-video: "\ea51";
$icon-vinyl: "\ea52";
$icon-voicemail: "\ea53";
$icon-wallet: "\ea54";
$icon-warning: "\ea55";
$icon-water: "\ea56";
$icon-px-with-circle: "\ea57";
$icon-px: "\ea58";
$icon-app-store: "\ea59";
$icon-baidu: "\ea5a";
$icon-basecamp: "\ea5b";
$icon-behance: "\ea5c";
$icon-creative-cloud: "\ea5d";
$icon-dribbble-with-circle: "\ea5e";
$icon-dribbble: "\ea5f";
$icon-dropbox: "\ea60";
$icon-evernote: "\ea61";
$icon-facebook-with-circle: "\ea62";
$icon-facebook: "\ea63";
$icon-flattr: "\ea64";
$icon-flickr-with-circle: "\ea65";
$icon-flickr: "\ea66";
$icon-foursquare: "\ea67";
$icon-github-with-circle: "\ea68";
$icon-github: "\ea69";
$icon-google-drive: "\ea6a";
$icon-google-hangouts: "\ea6b";
$icon-google-play: "\ea6c";
$icon-google-with-circle: "\ea6d";
$icon-google: "\ea6e";
$icon-grooveshark: "\ea6f";
$icon-houzz: "\ea70";
$icon-icloud: "\ea71";
$icon-instagram-with-circle: "\ea72";
$icon-instagram: "\ea73";
$icon-lastfm-with-circle: "\ea74";
$icon-lastfm: "\ea75";
$icon-linkedin-with-circle: "\ea76";
$icon-linkedin: "\ea77";
$icon-mail-with-circle: "\ea78";
$icon-medium-with-circle: "\ea79";
$icon-medium: "\ea7a";
$icon-mixi: "\ea7b";
$icon-onedrive: "\ea7c";
$icon-paypal: "\ea7d";
$icon-picasa: "\ea7e";
$icon-pinterest-with-circle: "\ea7f";
$icon-pinterest: "\ea80";
$icon-qq-with-circle: "\ea81";
$icon-qq: "\ea82";
$icon-raft-with-circle: "\ea83";
$icon-raft: "\ea84";
$icon-rainbow: "\ea85";
$icon-rdio-with-circle: "\ea86";
$icon-rdio: "\ea87";
$icon-renren: "\ea88";
$icon-scribd: "\ea89";
$icon-sina-weibo: "\ea8a";
$icon-skype-with-circle: "\ea8b";
$icon-skype: "\ea8c";
$icon-slideshare: "\ea8d";
$icon-smashing: "\ea8e";
$icon-soundcloud: "\ea8f";
$icon-spotify-with-circle: "\ea90";
$icon-spotify: "\ea91";
$icon-stumbleupon-with-circle: "\ea92";
$icon-stumbleupon: "\ea93";
$icon-swarm: "\ea94";
$icon-tripadvisor: "\ea95";
$icon-tumblr-with-circle: "\ea96";
$icon-tumblr: "\ea97";
$icon-twitter-with-circle: "\ea98";
$icon-twitter: "\ea99";
$icon-vimeo-with-circle: "\ea9a";
$icon-vimeo: "\ea9b";
$icon-vine-with-circle: "\ea9c";
$icon-vine: "\ea9d";
$icon-vk-alternitive: "\ea9e";
$icon-vk-with-circle: "\ea9f";
$icon-vk: "\eaa0";
$icon-windows-store: "\eaa1";
$icon-xing-with-circle: "\eaa2";
$icon-xing: "\eaa3";
$icon-yelp: "\eaa4";
$icon-youko-with-circle: "\eaa5";
$icon-youko: "\eaa6";
$icon-youtube-with-circle: "\eaa7";
$icon-youtube: "\eaa8";
$icon-chevron-double-left: "\ead5";
$icon-chevron-double-right: "\ead6";
$icon-logo-mydesigual: "\ead4";



$logo-desigual: "\e916";