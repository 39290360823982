// -------------------- FRAMEWORK VARIABLES ----------------------------


/*border-color: $stateHighlightBorderColor;
background: $stateHighlightBgColor;
color: $stateHighlightTextColor;*/
$dgl-primary: #8bbe43;



// -------------------- NEW VARIABLES ----------------------------

// COLORS
$dgl-bboa-color-secondary: #43a8bd;
$dgl-bboa-color-secondary-hover: #2f7685;

$dgl-bboa-header-backcolor: #0e1145;
$dgl-bboa-button-active: $dgl-primary;

/*
#8bb3c7
#005073
#ff9900
#43a8bd
#8bbe43*/
$dgl-bboa-color-grey-0: #fafcfc;
$dgl-bboa-color-grey-1: #f5f7f8;
$dgl-bboa-color-grey-2: #ebf0f2;
$dgl-bboa-color-grey-3: #e1e9ec;
$dgl-bboa-color-grey-4: #d7e2e6;
$dgl-bboa-color-grey-5: #95a5ac;
$dgl-bboa-color-grey-6: #525a72;

$dgl-bboa-menu-primary-color: #005073;
$dgl-bboa-menu-secondary-color: $dgl-bboa-color-grey-3;
$dgl-bboa-menu-tertiary-color: #2d3a64;
$dgl-bboa-kpis-back-color: #42436a;
$dgl-bboa-kpis-front-color: #ff7676;

$dgl-bboa-menu-line-color: rgba(200, 208, 213, .24);

$dgl-color-spinner: $dgl-bboa-header-backcolor;

// SIZEs
$dgl-bboa-header-height: 69px;
$dgl-bboa-breadcrumb-height: 40px;
$dgl-bboa-access-menu-height: 51px;
$dgl-bboa-menu-minsize: 45px;
$dgl-bboa-menu-size-check-xs: 22px;
$dgl-bboa-menu-size-check: 15px;

// FONT
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs:  $font-size-base * .625; //10px
$font-size-s:   $font-size-base * .75 !default; //12px;
$font-size-m:   $font-size-base * .875 !default; //14px;
$font-size-l:   $font-size-base * 1.125 !default; //18px;

$font-size-xxl: $font-size-base * 1.75; //28px


// LAYOUT
$main-layout-padding-y: 1.5rem;
$main-layout-padding-x: 1.5rem;


//TOOLTIP
//$tooltip-bg: black;


// TODO: Check the upper variables and remove the unuseful






:root {
    --item-product-size: 50px; // If change it update also DEFAULT_PRODUCT_ITEM_SIZE in grid.module.config.ts
}
