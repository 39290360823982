//custom bullets
.swiper-pagination-handle{

  [class*="swiper-pagination-bullet"] {
    background-color: $dgl-black;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active{
    opacity: 1;
  }
}

// mismos estilos que carousel.scss
.swiper-button-prev,
.swiper-button-next{
	background-image: none !important;
	font-size: 1.7em;
	width: 1em;
	height: 1em;
	cursor: pointer;

    &:before{
        @include icon-style;
        font-size: inherit;
        color: $dgl-gray-400;
    }

	&.swiper-button-disabled{}
}

.swiper-button-prev{
	 margin-left: -.4em;
        
    &:before{
        content: $icon-chevron-thin-left;
    }
}

.swiper-button-next{
	margin-right: -.4em;
        
    &:before{
        content: $icon-chevron-thin-right;
    }
}