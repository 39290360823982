@mixin font-custom{
  font-family: $font-custom;
  line-height: .9;
  font-weight: 400;
  letter-spacing: .025em;
  text-transform: uppercase;
}

@mixin regular-font{
  font-family: $font-family-light;
  font-weight: normal;
}

@mixin bold-font{
  font-family: $font-family-bold;
  font-weight: normal;
}