@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin aspect-ratio($width, $height) {
  &:before {
    content: "";
    display: block;
    width: 100%;
    z-index: -999999;
    padding-top: ($height / $width) * 100%;
  }
}
