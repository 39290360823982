
//
// the Bootstrap variables modified without modifying key, versioned files.
//


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;


$blue: $dgl-primary !default;
$red:  $dgl-red !default;
$yellow:  $dgl-yellow!default;
$green: $dgl-green !default;
$cyan: $dgl-light-blue !default;

$info: #43a8bd !default;


//DGL
$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $dgl-primary,
  "secondary":  $dgl-light-blue,
  "success":    $dgl-green,
  "danger":     $dgl-red,
  "light":      $white,
  "dark":       $dgl-gray-400,
  "black":      $black,
  "white":      $white
 ), $theme-colors);



// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;



// Components
//
// Define common padding and border radius sizes and more.


$border-width: 1px !default;

//DGL
$border-color: $gray-200 !default;
$border-radius:          0rem !default;
$border-radius-lg:       0rem !default;
$border-radius-sm:       0rem !default;



// Fonts
//
// Font, line-height, and color for body text, headings, and more.

//DGL
$font-family-sans-serif: $font-family-light, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   $font-size-base * 1.25 !default;
$font-size-sm:   $font-size-base * .875 !default;

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1 !default;



// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

//DGL
//$input-font-size-base:      1.25rem !default;
$input-font-size-base:      1rem !default;

$input-btn-padding-y:       .7rem !default;
$input-btn-padding-x:        1.5rem !default;
$input-btn-line-height:      1.25 !default;

$input-btn-padding-y-sm:    .44rem !default; //.25rem !default;
$input-btn-padding-x-sm:    1.2rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .9rem !default;
$input-btn-padding-x-lg:    1.5rem !default;
$input-btn-line-height-lg:  1.5 !default;

$input-btn-focus-width:          0 !default;
$btn-link-disabled-color:        $gray-400 !default;



// Forms

//DSG 

$input-padding-x:                1rem !default;
$input-padding-x-sm:             .7rem !default;
$input-padding-x-lg:             1.2rem !default;

$input-border-color:             $dgl-input-border-color !default;
$dgl-label-sm:                   $font-size-sm !default;
$dgl-label-lg:                   $font-size-lg !default;

$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

$input-focus-border-color:       $dgl-input-focus-border-color !default;
$input-focus-box-shadow:         $input-box-shadow !default;
$input-placeholder-color:        $gray-400 !default;
$input-height-inner:            ($input-font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;

$dropdown-border-color:          $input-border-color !default;

// Cards

//DGL
$card-spacer-y:            .8rem !default;
$card-border-width:        1px !default;
$card-border-color:        rgba($black,.10) !default;


// Tooltips

//DGL
$tooltip-max-width:           16rem !default;
$tooltip-bg:                  theme-color("primary") !default;
$tooltip-padding-y:           .5rem !default;
$tooltip-padding-x:           1rem !default;
$tooltip-arrow-width:         2.4rem !default;
$tooltip-arrow-height:        1rem !default;


// Alerts
//
// Define alert colors, border radius, and padding.

//DGL
$alert-padding-y:             1.5rem !default;
$alert-padding-x:             1.5rem !default;
$alert-margin-bottom:         1rem !default;



