// scss-lint:disable QualifyingElement, VendorPrefix

//
// Textual form controls
//

.form-control {
	font-size: $input-font-size-base;

	&::placeholder {
		color: $dgl-gray-400;
	}

	&.disabled,
    &:disabled {
		background-color: $dgl-gray-300;
        border-color: $dgl-gray-300;
        color: $dgl-gray-400;
        cursor: default;
        opacity: .65;
	}
}

.form-control-sm {
	font-size: $dgl-input-font-size-base-sm;
}

.form-control-lg {
	font-size: $dgl-input-font-size-base-lg;
}

.form-check-inline + .form-check-inline {
	margin-left:  $dgl-check-width + 1rem;
}

.label,
.label-sm,
.label-lg {
	color: $dgl-grey-strong;
}

.label-sm {
	font-size: $dgl-label-sm;
}

.label {
	font-size: $font-size-base;
}

.label-lg {
	font-size: $dgl-label-lg;
}

.form-sub{
	padding-left: 3em;
}

.was-validated .form-control:valid, 
.was-validated .form-control:invalid,
.form-control.is-valid, 
.form-control.is-invalid,
.was-validated .custom-select:valid,
.was-validated .custom-select:invalid,
.custom-select.is-valid,
.custom-select.is-invalid{
	
	&:focus{
		box-shadow: none !important;
	}
}

label {
    margin-bottom: .2rem;
}