.form-single {
	margin: 0 auto;
	max-width: $dgl-form-single-width;

	.single-input {
		display: block;
		max-width: 26rem;
	}
	
	.single-input-md {
		display: block;
		max-width: 16rem;
	}

	.single-input-sm {
		display: block;
		max-width: 11rem;
	}

	.single-input-xs {
		display: block;
		max-width: 8rem;
	}

	.single-dropdown {
		display: block;
		max-width: 20rem;
	}

	.single-dropdown-xs {
		display: inline-block;
		max-width: 8rem;
	}
}

.form-single-sm {
	max-width: $dgl-form-single-width-sm;
}