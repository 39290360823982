h1{
	font-weight: normal;
}

h2{
	@include regular-font;
	font-weight: normal;
	font-size: 1.2rem;
	margin-bottom: 1.5rem;
}

h4{
	text-transform: uppercase;
	margin-bottom: 1.5rem;
}